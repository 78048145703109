<template>
  <CBox>
    <CBox v-if="currentClubRequests.data.length > 0" pb="32px">
      <CText
        fontSize="16px"
        fontStyle="normal"
        fontWeight="700"
        color="#31373E"
        mb="16px"
        >Request to Join</CText
      >
      <CBox v-for="(v, i) in currentClubRequests.data" :key="i">
        <CBox bg="white" overflow="hidden" rounded="md" mb="16px" w="100%">
          <CBox
            d="flex"
            alignItems="center"
            justifyContent="space-between"
            p="8px 16px"
            borderBottom="1px solid var(--Neutral-Light-Gray, #EFF1F5)"
          >
            <CBox d="flex" alignItems="center" w="74%">
              <CAvatar
                w="35px"
                h="35px"
                mr="16px"
                :name="v.user.emp_full_name.split(' ')[0]"
                fontSize="14px"
                :_hover="{ opacity: '0.8' }"
                cursor="pointer"
                @click.native="
                  $router.push({
                    name: 'Profile',
                    params: {
                      emp_id: v.emp_id,
                    },
                  })
                "
              ></CAvatar>
              <CBox
                d="flex"
                :flexDirection="['column', 'row', 'row', 'row']"
                :alignItems="['none', 'center', 'center', 'center']"
              >
                <CText
                  textAlign="left"
                  mr="8px"
                  fontSize="14px"
                  w="fit-content"
                  :_hover="{ opacity: '0.8' }"
                  cursor="pointer"
                  @click.native="
                    $router.push({
                      name: 'Profile',
                      params: {
                        emp_id: v.emp_id,
                      },
                    })
                  "
                  >{{ v.user.emp_full_name }}</CText
                >
                <CText
                  textAlign="left"
                  fontWeight="600"
                  :fontSize="['10px', '12px', '12px', '12px']"
                  :bg="['#EFF1F5', 'none', 'none', 'none']"
                  w="fit-content"
                  :px="['6px', '0', '0', '0']"
                  :mt="['8px', '0', '0', '0']"
                  :borderRadius="['4px', '0', '0', '0']"
                  >Request</CText
                >
              </CBox>
            </CBox>
            <CBox d="flex" justifyContent="end" alignItems="center" w="25%">
              <CButton
                bg="bgButtonClub"
                color="white"
                :_hover="{ opacity: '0.8' }"
                my="2"
                size="0"
                w="fit-content"
                px="16px"
                py="8px"
                @click="requestAccept(v.id)"
                fontSize="12px"
                mr="14px"
                borderRadius="6px"
                fontWeight="600"
                :d="['none', 'block', 'block', 'block']"
              >
                Acccept
              </CButton>
              <CButton
                color="danger"
                bg="white"
                :_hover="{ opacity: '0.8' }"
                my="2"
                @click="requestDecline(v.id)"
                fontWeight="600"
                fontSize="12px"
                :d="['none', 'block', 'block', 'block']"
              >
                Decline
              </CButton>
              <CBox
                :d="['flex', 'none', 'none', 'none']"
                justifyContent="center"
                alignItems="center"
                w="20px"
                h="20px"
                bg="dangerLight"
                py="0"
                px="0"
                borderRadius="100%"
                mr="2"
                @click="requestDecline(v.id)"
              >
                <CImage :src="require('@/assets/icon/icon-close-red.svg')" />
              </CBox>
              <CBox
                :d="['flex', 'none', 'none', 'none']"
                justifyContent="center"
                alignItems="center"
                w="20px"
                h="20px"
                bg="bgButtonClub"
                borderRadius="100%"
                @click="requestAccept(v.id)"
              >
                <CImage :src="require('@/assets/icon/icon-checklist.svg')" />
              </CBox>
            </CBox>
          </CBox>
          <CText
            fontSize="14px"
            fontWeight="400"
            px="14px"
            mt="8px"
            w="100%"
            overflowX="auto"
          >
            {{ v.emp_reason }}
          </CText>
        </CBox>
      </CBox>
    </CBox>
  </CBox>
</template>

<script>
import { CBox, CText, CButton } from "@chakra-ui/vue";
import {
  GET_CLUB_REQUESTS,
  POST_CLUB_REQUEST_PROCESS,
} from "@/store/club.module";
import Swal from "sweetalert2";

export default {
  name: "RequestMember",
  components: {
    CBox,
    CText,
    CButton,
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    currentClubRequests() {
      return this.$store.getters.currentClubRequests || [];
    },
    totalPages() {
      return Math.ceil(
        this.currentClubRequests.meta.total /
          this.currentClubRequests.meta.limit
      );
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        this.$emit("acceptMember");
        const params = {
          slug: this.$route.params.slug,
          data: {
            page: this.currentPage,
            limit: 10,
            sortBy: "update_at",
            status: "pending",
          },
        };

        await this.$store.dispatch(GET_CLUB_REQUESTS, params);
      } catch (err) {
        console.log(err);
      }
    },
    async requestAccept(id) {
      try {
        const params = {
          slug: this.$route.params.slug,
          data: {
            is_accepted: true,
            club_member_approval_id: id,
            note: "",
          },
        };
        if (this.currentUser) {
          await this.$store.dispatch(POST_CLUB_REQUEST_PROCESS, params);
          await this.getData();
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "success",
            title: "Join request accepted successfully",
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async requestDecline(id) {
      const params = {
        slug: this.$route.params.slug,
        data: {
          is_accepted: false,
          club_member_approval_id: id,
          note: "",
        },
      };

      if (this.currentUser) {
        try {
          const result = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, decline it!",
          });

          if (result.isConfirmed) {
            await this.$store.dispatch(POST_CLUB_REQUEST_PROCESS, params);
            await this.getData();
            Swal.fire({
              title: "Declined!",
              text: "Join request has been declined.",
              icon: "success",
            });
          }
        } catch (error) {
          console.error("Error processing club request:", error);
        }
      }
    },
    setCurrentPage(page) {
      this.currentPage = page;
      this.getData();
    },
  },
};
</script>
