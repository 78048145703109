import { render, staticRenderFns } from "./MembersClub.vue?vue&type=template&id=c2ceafc6"
import script from "./MembersClub.vue?vue&type=script&lang=js"
export * from "./MembersClub.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CAvatar: require('@chakra-ui/vue').CAvatar, CButton: require('@chakra-ui/vue').CButton, CBox: require('@chakra-ui/vue').CBox})
