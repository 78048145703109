import { render, staticRenderFns } from "./DetailClub.vue?vue&type=template&id=4b7a5ad9"
import script from "./DetailClub.vue?vue&type=script&lang=js"
export * from "./DetailClub.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CImage: require('@chakra-ui/vue').CImage, CLink: require('@chakra-ui/vue').CLink, CText: require('@chakra-ui/vue').CText, CButton: require('@chakra-ui/vue').CButton, CAlertIcon: require('@chakra-ui/vue').CAlertIcon, CCloseButton: require('@chakra-ui/vue').CCloseButton, CAlert: require('@chakra-ui/vue').CAlert})
