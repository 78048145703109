var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CBox',[(_vm.currentClubRequests.data.length > 0)?_c('CBox',{attrs:{"pb":"32px"}},[_c('CText',{attrs:{"fontSize":"16px","fontStyle":"normal","fontWeight":"700","color":"#31373E","mb":"16px"}},[_vm._v("Request to Join")]),_vm._l((_vm.currentClubRequests.data),function(v,i){return _c('CBox',{key:i},[_c('CBox',{attrs:{"bg":"white","overflow":"hidden","rounded":"md","mb":"16px","w":"100%"}},[_c('CBox',{attrs:{"d":"flex","alignItems":"center","justifyContent":"space-between","p":"8px 16px","borderBottom":"1px solid var(--Neutral-Light-Gray, #EFF1F5)"}},[_c('CBox',{attrs:{"d":"flex","alignItems":"center","w":"74%"}},[_c('CAvatar',{attrs:{"w":"35px","h":"35px","mr":"16px","name":v.user.emp_full_name.split(' ')[0],"fontSize":"14px","_hover":{ opacity: '0.8' },"cursor":"pointer"},nativeOn:{"click":function($event){return _vm.$router.push({
                  name: 'Profile',
                  params: {
                    emp_id: v.emp_id,
                  },
                })}}}),_c('CBox',{attrs:{"d":"flex","flexDirection":['column', 'row', 'row', 'row'],"alignItems":['none', 'center', 'center', 'center']}},[_c('CText',{attrs:{"textAlign":"left","mr":"8px","fontSize":"14px","w":"fit-content","_hover":{ opacity: '0.8' },"cursor":"pointer"},nativeOn:{"click":function($event){return _vm.$router.push({
                    name: 'Profile',
                    params: {
                      emp_id: v.emp_id,
                    },
                  })}}},[_vm._v(_vm._s(v.user.emp_full_name))]),_c('CText',{attrs:{"textAlign":"left","fontWeight":"600","fontSize":['10px', '12px', '12px', '12px'],"bg":['#EFF1F5', 'none', 'none', 'none'],"w":"fit-content","px":['6px', '0', '0', '0'],"mt":['8px', '0', '0', '0'],"borderRadius":['4px', '0', '0', '0']}},[_vm._v("Request")])],1)],1),_c('CBox',{attrs:{"d":"flex","justifyContent":"end","alignItems":"center","w":"25%"}},[_c('CButton',{attrs:{"bg":"bgButtonClub","color":"white","_hover":{ opacity: '0.8' },"my":"2","size":"0","w":"fit-content","px":"16px","py":"8px","fontSize":"12px","mr":"14px","borderRadius":"6px","fontWeight":"600","d":['none', 'block', 'block', 'block']},on:{"click":function($event){return _vm.requestAccept(v.id)}}},[_vm._v(" Acccept ")]),_c('CButton',{attrs:{"color":"danger","bg":"white","_hover":{ opacity: '0.8' },"my":"2","fontWeight":"600","fontSize":"12px","d":['none', 'block', 'block', 'block']},on:{"click":function($event){return _vm.requestDecline(v.id)}}},[_vm._v(" Decline ")]),_c('CBox',{attrs:{"d":['flex', 'none', 'none', 'none'],"justifyContent":"center","alignItems":"center","w":"20px","h":"20px","bg":"dangerLight","py":"0","px":"0","borderRadius":"100%","mr":"2"},on:{"click":function($event){return _vm.requestDecline(v.id)}}},[_c('CImage',{attrs:{"src":require('@/assets/icon/icon-close-red.svg')}})],1),_c('CBox',{attrs:{"d":['flex', 'none', 'none', 'none'],"justifyContent":"center","alignItems":"center","w":"20px","h":"20px","bg":"bgButtonClub","borderRadius":"100%"},on:{"click":function($event){return _vm.requestAccept(v.id)}}},[_c('CImage',{attrs:{"src":require('@/assets/icon/icon-checklist.svg')}})],1)],1)],1),_c('CText',{attrs:{"fontSize":"14px","fontWeight":"400","px":"14px","mt":"8px","w":"100%","overflowX":"auto"}},[_vm._v(" "+_vm._s(v.emp_reason)+" ")])],1)],1)})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }