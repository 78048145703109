<template>
  <CBox>
    <Loading :active="isLoadingComment" />
    <CBox
      v-if="
        currentClubActivities.data &&
        currentClubActivities.data.length > 0 &&
        !isLoading
      "
    >
      <ModalComments
        :isOpen="currentCommentModalStatus"
        :result_id="result_id"
        :title="`Comments for ${titleComment}`"
        :comments="currentComments"
        :user_suggestions="currentUserSuggestions"
        :hashtag_suggestions="currentHashtagSuggestions"
        :type="'RESULT'"
        @updateData="updateData"
      />
      <CBox p="0" py="8px" mt="-8px">
        <CBox
          v-for="v in currentClubActivities.data"
          :key="v.id"
          alignItems="center"
          mb="2"
          shadow="sm"
          rounded="md"
          p="16px"
        >
          <CBox>
            <CBox
              d="flex"
              :alignItems="['none', 'center', 'center', 'center']"
              pb="16px"
              borderBottom="1px solid var(--Neutral-Light-Gray, #EFF1F5)"
              :flexDirection="['column', 'row', 'row', 'row']"
            >
              <CBox mr="auto" :mb="['8px', '0', '0', '0']">
                <CText
                  fontSize="16px"
                  fontWeight="600"
                  textTransform="capitalize"
                  cursor="pointer"
                  :_hover="{ opacity: '0.8' }"
                  @click.native="directLinkChallenge(v.app_type.slug)"
                >
                  {{ truncateText(v.app_type.name, 200) }}
                </CText>
                <CText
                  v-if="
                    mergedParticipants[v.app_type.id] &&
                    Object.values(mergedParticipants[v.app_type.id]) &&
                    Object.values(mergedParticipants[v.app_type.id]).length == 1
                  "
                  fontSize="11px"
                  fontWeight="400"
                  textTransform="capitalize"
                >
                  <CText d="inline-block" fontWeight="600">
                    <CText
                      d="inline-block"
                      w="fit-content"
                      :_hover="{ opacity: '0.8' }"
                      cursor="pointer"
                      @click.native="
                        $router.push({
                          name: 'Profile',
                          params: {
                            emp_id: v.app_type.id,
                          },
                        })
                      "
                    >
                      {{
                        Object.values(
                          mergedParticipants[v.app_type.id]
                        )[0][0].split(" ")[0]
                      }}
                    </CText>
                  </CText>
                  joined this challenge
                </CText>
                <CText
                  v-else-if="
                    mergedParticipants[v.app_type.id] &&
                    Object.values(mergedParticipants[v.app_type.id]) &&
                    Object.values(mergedParticipants[v.app_type.id]).length == 2
                  "
                  fontSize="11px"
                  fontWeight="400"
                  textTransform="capitalize"
                >
                  <CText d="inline-block" fontWeight="600">
                    <CText
                      d="inline-block"
                      w="fit-content"
                      :_hover="{ opacity: '0.8' }"
                      cursor="pointer"
                      @click.native="
                        $router.push({
                          name: 'Profile',
                          params: {
                            emp_id: v.app_type.id,
                          },
                        })
                      "
                    >
                      {{
                        Object.values(
                          mergedParticipants[v.app_type.id]
                        )[0][0].split(" ")[0]
                      }}
                    </CText>
                    and
                    <CText
                      d="inline-block"
                      w="fit-content"
                      :_hover="{ opacity: '0.8' }"
                      cursor="pointer"
                      @click.native="
                        $router.push({
                          name: 'Profile',
                          params: {
                            emp_id: v.app_type.id,
                          },
                        })
                      "
                    >
                      {{
                        Object.values(
                          mergedParticipants[v.app_type.id]
                        )[1][0].split(" ")[0]
                      }}
                    </CText>
                  </CText>
                  joined this challenge
                </CText>
                <CText
                  fontSize="11px"
                  fontWeight="400"
                  textTransform="capitalize"
                  v-else-if="
                    mergedParticipants[v.app_type.id] &&
                    Object.values(mergedParticipants[v.app_type.id]) &&
                    Object.values(mergedParticipants[v.app_type.id]).length > 2
                  "
                >
                  <CText d="inline-block" fontWeight="600">
                    <CText
                      d="inline-block"
                      w="fit-content"
                      @click.native="
                        $router.push({
                          name: 'Profile',
                          params: {
                            emp_id: v.app_type.id,
                          },
                        })
                      "
                    >
                      {{
                        Object.values(
                          mergedParticipants[v.app_type.id]
                        )[0][0].split(" ")[0]
                      }} </CText
                    >,
                    <CText
                      d="inline-block"
                      w="fit-content"
                      :_hover="{ opacity: '0.8' }"
                      cursor="pointer"
                      @click.native="
                        $router.push({
                          name: 'Profile',
                          params: {
                            emp_id: v.app_type.id,
                          },
                        })
                      "
                    >
                      {{
                        Object.values(
                          mergedParticipants[v.app_type.id]
                        )[1][0].split(" ")[0]
                      }}</CText
                    >
                  </CText>
                  and
                  <CText d="inline-block" fontWeight="600"
                    >{{
                      Object.values(mergedParticipants[v.app_type.id]).length -
                      2
                    }}
                    Others</CText
                  >
                  joined this challenge
                </CText>
              </CBox>
              <CButton
                bg="bgButtonClub"
                color="white"
                :_hover="{ opacity: '0.8' }"
                fontSize="11px"
                size="0"
                w="fit-content"
                px="16px"
                py="8px"
                @click="directLinkChallenge(v.app_type.slug)"
              >
                Join Challenge
              </CButton>
            </CBox>
            <CBox
              v-for="(participant, i) in v.participant"
              :key="i"
              d="flex"
              :alignItems="['none', 'center', 'center', 'center']"
              py="24px"
              px="16px"
              borderBottom="1px solid var(--Neutral-Light-Gray, #EFF1F5)"
              :flexDirection="['column', 'row', 'row', 'row']"
              w="full"
            >
              <CBox
                d="flex"
                alignItems="center"
                :w="['full', '70%', '70%', '70%']"
                :mr="['0', '16px', '16px', '16px']"
                :mb="['16px', '0', '0', '0']"
              >
                <CAvatar
                  w="35px"
                  h="35px"
                  mr="12px"
                  :name="participant.user.emp_full_name.split(' ')[0]"
                  :_hover="{ opacity: '0.8' }"
                  cursor="pointer"
                  @click.native="
                    $router.push({
                      name: 'Profile',
                      params: {
                        emp_id: participant.user.emp_id,
                      },
                    })
                  "
                ></CAvatar>
                <CBox w="full">
                  <CText
                    w="fit-content"
                    fontSize="14px"
                    fontWeight="600"
                    :_hover="{ opacity: '0.8' }"
                    cursor="pointer"
                    @click.native="
                      $router.push({
                        name: 'Profile',
                        params: {
                          emp_id: participant.user.emp_id,
                        },
                      })
                    "
                    >{{
                      participant.user.emp_id === currentUser.emp_id
                        ? "You"
                        : participant.user.emp_full_name
                    }}</CText
                  >
                  <CBox d="flex" alignItems="center">
                    <CText fontSize="11px" fontWeight="400" mr="8px">
                      {{
                        formatTimeAgo(
                          participant.updated_at || participant.created_at
                        )
                      }}
                    </CText>
                    <CText
                      color="main"
                      fontSize="20px"
                      fontWeight="700"
                      mr="4px"
                      >+</CText
                    >
                    <CText
                      color="main"
                      fontSize="16px"
                      fontWeight="700"
                      mr="4px"
                      >{{ participant.xp }}</CText
                    >
                    <CText color="main" fontSize="10px" fontWeight="400"
                      >Exp</CText
                    >
                    <CBox
                      :d="['flex', 'none', 'none', 'none']"
                      justifyContent="space-between"
                      alignItems="center"
                      ml="auto"
                      mt="2px"
                    >
                      <CBox
                        cursor="pointer"
                        :_hover="{ opacity: '1' }"
                        :key="participant.result_id"
                        @click.native="
                          postResultLike(participant.result_id);
                          $event.stopPropagation();
                        "
                        d="flex"
                        flexDirection="column"
                        alignItems="center"
                        mr="16px"
                      >
                        <CImage
                          :src="
                            participant.is_liked === 1
                              ? require('@/assets/icon/icon-heart-solid.svg')
                              : require('@/assets/icon/icon-heart-regular.svg')
                          "
                          w="24px"
                          h="24px"
                        />
                        <CText fontSize="10px" color="#ACACAC" mt="4px">
                          {{ participant.total_like }}
                        </CText>
                      </CBox>
                      <CBox
                        cursor="pointer"
                        :_hover="{ opacity: '1' }"
                        @click.native="
                          showCommentsModal(
                            participant.result_id,
                            participant.user.emp_full_name,
                            truncateText(v.app_type.name, 50)
                          );
                          $event.stopPropagation();
                        "
                        d="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <CImage
                          :src="
                            require('@/assets/icon/icon-comment-regular.svg')
                          "
                          w="24px"
                          h="24px"
                        />
                        <CText fontSize="10px" color="#ACACAC" mt="4px">
                          {{ participant.total_comment }}
                        </CText>
                      </CBox>
                    </CBox>
                  </CBox>
                </CBox>
              </CBox>
              <CBox d="flex" justifyContent="space-between" w="full">
                <CBox
                  :_hover="{ opacity: '0.8' }"
                  cursor="pointer"
                  @click.native="
                    $router.push({
                      name: 'Detail Job',
                      params: {
                        id: participant.result_id,
                      },
                    })
                  "
                  :w="['30%', '20%', '20%', '20%']"
                >
                  <CText fontSize="12px" fontWeight="400">Tech Stack</CText>
                  <CBox d="flex" alignItems="center" mt="4px">
                    <CImage
                      v-if="
                        languages.includes(
                          participant.hashtag[0].tag.name.toLowerCase()
                        )
                      "
                      :src="
                        require('@/assets/icon/icon-' +
                          participant.hashtag[0].tag.name
                            .toLowerCase()
                            .replace('#', 's') +
                          '.svg')
                      "
                      :alt="participant.hashtag[0].tag.name.toLowerCase()"
                      :title="participant.hashtag[0].tag.name.toLowerCase()"
                      mr="8px"
                      w="20px"
                      d="inline-block"
                    />
                    <CText
                      fontSize="12px"
                      fontWeight="400"
                      d="flex"
                      alignItems="center"
                      textTransform="capitalize"
                    >
                      {{
                        participant.hashtag[0].tag.name
                          ? participant.hashtag[0].tag.name
                          : "-"
                      }}
                    </CText>
                  </CBox>
                </CBox>
                <CBox
                  :_hover="{ opacity: '0.8' }"
                  cursor="pointer"
                  @click.native="
                    $router.push({
                      name: 'Detail Job',
                      params: {
                        id: participant.result_id,
                      },
                    })
                  "
                  px="8px"
                >
                  <CText fontSize="12px" fontWeight="400"
                    >Total Durations</CText
                  >
                  <CText
                    fontSize="16px"
                    fontWeight="600"
                    d="flex"
                    alignItems="center"
                  >
                    {{ formatDuration(participant.total_duration) }}
                    <CText
                      fontSize="10px"
                      fontWeight="400"
                      d="inline-block"
                      ml="4px"
                      >Min</CText
                    >
                  </CText>
                </CBox>
                <CBox
                  :_hover="{ opacity: '0.8' }"
                  cursor="pointer"
                  @click.native="
                    $router.push({
                      name: 'Detail Job',
                      params: {
                        id: participant.result_id,
                      },
                    })
                  "
                >
                  <CText fontSize="12px" fontWeight="400">Total Points</CText>
                  <CText
                    fontSize="16px"
                    fontWeight="600"
                    d="flex"
                    alignItems="center"
                  >
                    {{ participant.total_point }}
                    <CText
                      fontSize="10px"
                      fontWeight="400"
                      d="inline-block"
                      ml="4px"
                      >Point</CText
                    >
                  </CText>
                </CBox>
                <CBox
                  :d="['none', 'flex', 'flex', 'flex']"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <CBox
                    cursor="pointer"
                    :_hover="{ opacity: '1' }"
                    :key="participant.result_id"
                    @click.native="
                      postResultLike(
                        participant.result_id,
                        currentUser && currentUser.length > 0
                      );
                      $event.stopPropagation();
                    "
                    d="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <CImage
                      :src="
                        participant.is_liked === 1
                          ? require('@/assets/icon/icon-heart-solid.svg')
                          : require('@/assets/icon/icon-heart-regular.svg')
                      "
                      w="24px"
                      h="24px"
                      mr="16px"
                    />
                    <CText fontSize="10px" color="#ACACAC" mr="16px" mt="4px">
                      {{ participant.total_like }}
                    </CText>
                  </CBox>
                  <CBox
                    cursor="pointer"
                    :_hover="{ opacity: '1' }"
                    @click.native="
                      showCommentsModal(
                        participant.result_id,
                        participant.user.emp_full_name,
                        truncateText(v.app_type.name, 20)
                      );
                      $event.stopPropagation();
                    "
                    d="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <CImage
                      :src="require('@/assets/icon/icon-comment-regular.svg')"
                      w="24px"
                      h="24px"
                    />
                    <CText fontSize="10px" color="#ACACAC" mt="4px">
                      {{ participant.total_comment }}
                    </CText>
                  </CBox>
                </CBox>
              </CBox>
            </CBox>
          </CBox>
        </CBox>
      </CBox>
    </CBox>
    <CBox v-else>
      <CText>{{
        isLoading ? "Loading..." : "There is no activity yet."
      }}</CText>
    </CBox>
  </CBox>
</template>

<script>
import Loading from "vue-loading-overlay";
import { CBox, CText, CButton, CImage, CAvatar } from "@chakra-ui/vue";
import ModalComments from "@/components/modal/ModalComments.vue";
import { GET_CLUB_ACTIVITIES } from "@/store/club.module";
import { POST_LIKE, REMOVE_LIKE } from "@/store/like.module";
import {
  SET_TOGGLE_COMMENTS_MODAL,
  GET_COMMENTS,
  GET_USER_SUGGESTIONS,
} from "@/store/comment.module";
import config from "@/ConfigProvider";

export default {
  name: "DetailClub",
  components: {
    CBox,
    CText,
    CButton,
    CImage,
    CAvatar,
    ModalComments,
    Loading,
  },
  data() {
    return {
      isLoading: true,
      urlGethired: config.value("GETHIRED_WEB_URL"),
      isLoadingComment: false,
      currentPage: 1,
      result_id: "",
      titleComment: "",
      comments: "",
      mergedParticipants: {},
      languages: [
        "javascript",
        "php",
        "go",
        "python",
        "java",
        "c#",
        "rust",
        "ruby",
        "dart",
        "kotlin",
        "c",
        "c++",
        "typescript",
      ],
    };
  },
  props: {
    current_user: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser || [];
    },
    currentClubActivities() {
      return this.$store.getters.currentClubActivities || [];
    },
    currentComments() {
      return this.$store.getters.currentComments;
    },
    currentUserSuggestions() {
      return this.$store.getters.currentUserSuggestions;
    },
    currentHashtagSuggestions() {
      return this.$store.getters.currentHashtagSuggestions;
    },
    currentCommentModalStatus() {
      return this.$store.getters.currentCommentModalStatus;
    },
    totalPages() {
      return Math.ceil(
        this.currentClubActivities.meta.total /
          this.currentClubActivities.meta.limit
      );
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    handleLogin() {
      window.location.href = this.urlGethired + "/signin";
    },
    async getData() {
      try {
        const params = {
          slug: this.$route.params.slug,
          data: {
            page: this.currentPage,
            limit: 1000,
            sortBy: "update_at",
          },
        };
        await this.$store.dispatch(GET_CLUB_ACTIVITIES, params);
        this.mergeParticipants();
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },
    async postResultLike(id) {
      if (this.current_user) {
        try {
          await this.$store.dispatch(POST_LIKE, {
            type: "result",
            id: id,
          });
        } catch (err) {
          if (err.response.data.message === "Already like this content") {
            await this.$store.dispatch(REMOVE_LIKE, {
              type: "result",
              id: id,
            });
          }
        } finally {
          this.getData();
        }
      } else {
        this.handleLogin();
      }
    },
    mergeParticipants() {
      this.currentClubActivities.data.forEach((activity) => {
        const activityId = activity.app_type.id;
        activity.participant.forEach((participant) => {
          const empId = participant.user.emp_id;
          const fullName = participant.user.emp_full_name;

          if (empId !== this.currentUser.emp_id) {
            if (!this.mergedParticipants[activityId]) {
              this.$set(this.mergedParticipants, activityId, {});
            }

            if (!this.mergedParticipants[activityId][empId]) {
              this.$set(this.mergedParticipants[activityId], empId, []);
            }

            // Cek apakah nama lengkap sudah ada dalam array
            if (
              !this.mergedParticipants[activityId][empId].includes(fullName)
            ) {
              this.mergedParticipants[activityId][empId].push(fullName);
            }
          }
        });
      });
    },
    async showCommentsModal(id, name, title) {
      try {
        this.isLoadingComment = true;
        this.result_id = id;
        this.titleComment = name + " - " + title;
        await this.$store.dispatch(GET_COMMENTS, { id: id });
        await this.$store.dispatch(GET_USER_SUGGESTIONS, {
          id: id,
        });
      } finally {
        this.isLoadingComment = false;
        this.$store.commit(SET_TOGGLE_COMMENTS_MODAL, true);
      }
    },
    directLinkChallenge(slug) {
      this.$router.push("/challenge/" + slug).then(() => {
        setTimeout(() => {
          window.location.reload(true);
        }, 0);
      });
    },
    truncateText(text, maxLength) {
      if (text && text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
      }
      return text;
    },
    parseUpdatedAt(updatedAt) {
      return new Date(updatedAt.replace(/ /g, "T"));
    },
    formatTimeAgo(updatedAt) {
      const now = new Date();
      const updatedAtDate = this.parseUpdatedAt(updatedAt);
      const timeDifference = now - updatedAtDate;

      if (timeDifference < 1000) {
        return "Just now";
      } else if (timeDifference < 60000) {
        const seconds = Math.floor(timeDifference / 1000);
        return `${seconds} seconds ago`;
      } else if (timeDifference < 3600000) {
        const minutes = Math.floor(timeDifference / 60000);
        return `${minutes} minutes ago`;
      } else if (timeDifference < 86400000) {
        const hours = Math.floor(timeDifference / 3600000);
        return `${hours} hours ago`;
      } else if (timeDifference < 2592000000) {
        const days = Math.floor(timeDifference / 86400000);
        return `${days} days ago`;
      } else if (timeDifference < 31536000000) {
        const months = Math.floor(timeDifference / 2592000000);
        return `${months} months ago`;
      } else {
        const years = Math.floor(timeDifference / 31536000000);
        return `${years} years ago`;
      }
    },
    formatDuration(duration) {
      const date = new Date(duration);
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();

      return `${minutes}.${seconds}`;
    },
    setCurrentPage(page) {
      this.currentPage = page;
      this.getData();
    },
    updateData() {
      this.getData();
    },
  },
};
</script>
