<template>
  <CBox d="flex" justifyContent="space-between" flexWrap="wrap">
    <CBox v-if="isLoading || errorMessage === 'club not found'"
      ><Loading :active="isLoading"
    /></CBox>
    <CBox
      v-else-if="!isLoading"
      minH="100hv"
      borderRadius="5px"
      boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
      :w="['100%', '100%', '74%', '74%']"
      mt="5"
      bg="white"
      :p="['10px', '24px', '24px', '24px']"
    >
      <CBox
        position="absolute"
        top="1em"
        right="1em"
        cursor="pointer"
        d="flex"
        alignItems="center"
        boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
      >
      </CBox>
      <JoinClub
        v-if="
          currentUser && !currentUser.urole_id && currentClub.is_joined === 0
        "
        :isModalOpen="isModalOpen"
        :is_need_approval="currentClub.is_need_approval"
        :slug="currentSlug"
        :rule="currentClub.rule"
        @closeModal="closeModal"
      />
      <CBox d="flex" justifyContent="space-between">
        <CImage
          :src="
            currentClub.logo
              ? currentClub.logo
              : require('@/assets/img/default-profile.png')
          "
          boxSize="150px"
          borderRadius="5"
          w="10"
        />
        <CLink
          v-if="currentClub.role === 'owner' || currentClub.role === 'admin'"
          :href="`/clubs/${currentSlug}/edit`"
          color="bgButtonClub"
          bg="white"
          border="#0278B7 1px solid"
          :_hover="{ textDecoration: 'none', opacity: 0.8 }"
          ml="2"
          py="1"
          px="2"
          fontSize="11px"
          rounded="md"
          h="fit-content"
          d="flex"
          alignItems="center"
          fontWeight="600"
        >
          <CImage
            :src="require('@/assets/icon/icon-edit.svg')"
            mr="1"
            w="20px"
          />
          Edit Club
        </CLink>
      </CBox>
      <CBox flex="1">
        <CBox my="8px" style="word-wrap: break-word">
          <CText
            d="flex"
            alignItems="center"
            fontSize="28px"
            fontWeight="700"
            color="#31373E"
            overflow="hidden"
            style="word-wrap: break-word"
          >
            {{ currentClub.name }}
          </CText>
          <CBox d="flex" alignItems="center" mb="8px" flexWrap="wrap">
            <CBox d="flex" alignItems="center" mr="2" overflow="hidden">
              <CImage
                :src="require('@/assets/icon/icon-location.svg')"
                alt="Location"
                title="Location"
                mr="1"
                w="20px"
              /><CText fontWeight="600" fontSize="14px" overflow="hidden">{{
                currentClub.location
              }}</CText>
            </CBox>
            <CBox d="flex"
              ><CImage
                :src="
                  require('@/assets/icon/icon-' +
                    checkType(currentClub.type).toLowerCase() +
                    '.svg')
                "
                alt="University"
                title="University"
                w="20px"
                mr="1"
                d="inline-block"
              />
              <CText d="inline-block" fontWeight="400" fontSize="14px">{{
                checkType(currentClub.type)
              }}</CText>
            </CBox>
          </CBox>
          <CBox d="flex" flexWrap="wrap" alignItems="center">
            <CBox
              v-for="(language, i) in currentClub.language"
              :key="i"
              d="flex"
              alignItems="center"
              mr="14px"
              mb="8px"
            >
              <CImage
                :src="
                  require('@/assets/icon/icon-' +
                    language.toLowerCase().replace('#', 's') +
                    '.svg')
                "
                :alt="language"
                :title="language"
                mr="1"
                w="20px"
                h="20px"
                d="inline-block"
              />
              <CText d="inline-block" fontWeight="400" fontSize="14px">{{
                language
              }}</CText>
            </CBox>
          </CBox>
          <CText
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
            style="word-wrap: break-word"
          >
            <span
              v-for="(text, index) in splitDescription"
              :key="index"
              style="word-wrap: break-word"
            >
              <template v-if="text.includes('<img')">
                <span
                  v-html="
                    text.replace('<img', '<img style=\'max-width: 200px;\'')
                  "
                ></span>
              </template>
              <template v-else style="word-wrap: break-word">
                {{ text }}
              </template>
              <br v-if="index !== splitDescription.length - 1" />
            </span>
          </CText>
        </CBox>
        <CBox>
          <CButton
            v-if="
              (currentUser &&
                !currentUser.urole_id &&
                currentClub.is_joined === 0 &&
                currentClub.status === 'decline') ||
              (currentUser &&
                !currentUser.urole_id &&
                currentClub.is_joined === 0 &&
                currentClub.status === 'not_joined') ||
              (currentUser &&
                !currentUser.urole_id &&
                currentClub.is_joined === 0 &&
                currentClub.status === 'accept')
            "
            bg="bgButtonClub"
            color="white"
            :_hover="{ opacity: '0.8' }"
            size="sm"
            mt="8px"
            mr="2"
            @click="openModal"
          >
            Join Club
          </CButton>
          <CButton
            v-else-if="!currentUser"
            bg="bgButtonClub"
            color="white"
            :_hover="{ opacity: '0.8' }"
            size="sm"
            mt="8px"
            mr="2"
            @click="handleLogin"
          >
            Join Club
          </CButton>
          <CAlert
            v-if="currentClub.status === 'pending'"
            status="info"
            bg="main"
            color="white"
            :class="{
              'alert-content-enter-active-fade-in': true,
              'alert-content-close-active': showAlert == false,
            }"
          >
            <CAlertIcon color="white" />
            Waiting for approval from admin club!
            <CCloseButton @click="hideAlert" ms="auto" />
          </CAlert>
        </CBox>
        <CBox
          v-if="
            (currentClub.is_private && currentClub.is_joined) ||
            !currentClub.is_private
          "
        >
          <CBox d="flex" mt="32px" mb="24px" overflowX="auto" w="100%">
            <CBox :mr="['14px', '24px', '24px', '24px']" @click="showTable = 1">
              <router-link :to="'/clubs/' + $route.params.slug + '/activity'">
                <CText
                  :color="showTable == 1 ? 'main' : 'gray'"
                  :borderBottom="showTable == 1 ? '2px solid #29AAFE' : 'none'"
                  cursor="pointer"
                  fontSize="16px"
                >
                  Activity
                </CText>
              </router-link>
            </CBox>
            <CBox :mr="['14px', '24px', '24px', '24px']" @click="showTable = 2">
              <router-link
                :to="'/clubs/' + $route.params.slug + '/leaderboard'"
                :mr="['14px', '24px', '24px', '24px']"
              >
                <CText
                  :color="showTable == 2 ? 'main' : 'gray'"
                  :borderBottom="showTable == 2 ? '2px solid #29AAFE' : 'none'"
                  fontSize="16px"
                >
                  Leaderboard
                </CText>
              </router-link>
            </CBox>
            <CBox :mr="['14px', '24px', '24px', '24px']" @click="showTable = 3">
              <router-link
                :to="'/clubs/' + $route.params.slug + '/members'"
                :mr="['14px', '24px', '24px', '24px']"
              >
                <CText
                  :color="showTable == 3 ? 'main' : 'gray'"
                  :borderBottom="showTable == 3 ? '2px solid #29AAFE' : 'none'"
                  fontSize="16px"
                >
                  Members
                </CText>
              </router-link>
            </CBox>
            <CBox :mr="['14px', '24px', '24px', '24px']" @click="showTable = 4">
              <router-link
                :to="'/clubs/' + $route.params.slug + '/posts'"
                :mr="['14px', '24px', '24px', '24px']"
              >
                <CText
                  :color="showTable == 4 ? 'main' : 'gray'"
                  :borderBottom="showTable == 4 ? '2px solid #29AAFE' : 'none'"
                  fontSize="16px"
                >
                  Posts
                </CText>
              </router-link>
            </CBox>
            <CBox
              @click="showTable = 5"
              :color="showTable == 5 ? 'main' : 'gray'"
              :borderBottom="showTable == 5 ? '2px solid #29AAFE' : 'none'"
              cursor="pointer"
              position="relative"
              fontSize="16px"
            >
              Rules
            </CBox>
          </CBox>
          <ActivityClub v-if="showTable == 1" :current_user="currentUser" />
          <LeaderboardClub v-else-if="showTable == 2" />
          <MembersClub
            v-else-if="showTable == 3"
            :isOwner="currentClub.role == 'owner'"
            :isAdmin="currentClub.role == 'admin'"
          />
          <PostClub v-else-if="showTable == 4" :current_user="currentUser" />
          <CBox v-else :pb="['24px', '0', '0', '0']" w="100%" overflowX="auto">
            <CText fontSize="14px" lineHeight="24px" fontWeight="400">
              <span v-for="(text, index) in splitRule" :key="index">
                {{ text }}
                <br v-if="index !== splitRule.length - 1" /> </span
            ></CText>
          </CBox>
        </CBox>
        <CBox
          v-else
          d="flex"
          alignItems="center"
          borderTop="1px solid var(--Neutral-Light-Gray, #EFF1F5)"
          pt="16px"
          mt="32px"
        >
          <CImage
            :src="require('@/assets/icon/icon-lock.svg')"
            w="40px"
            h="40px"
            p="5px"
            d="inline-block"
            border="solid 1px #31373E"
            borderRadius="full"
            mr="8px"
          />
          <CBox>
            <CText fontWeight="600" fontSize="14px">This club is private</CText>
            <CText fontWeight="400" fontSize="14px"
              >Join this club to see their content</CText
            >
          </CBox>
        </CBox>
      </CBox>
    </CBox>
    <CBox
      v-if="!isLoading && errorMessage !== 'club not found'"
      :w="['100%', '100%', '25%', '25%']"
    >
      <SidebarDetailClub />
    </CBox>
  </CBox>
</template>

<script>
import Loading from "vue-loading-overlay";
import { CBox, CText, CImage, CLink, CAlert, CAlertIcon } from "@chakra-ui/vue";
import {
  GET_CLUB_DETAIL,
  POST_CLUB_LEAVE_MEMBER,
  GET_CLUB_MEMBERS,
} from "@/store/club.module";
import SidebarDetailClub from "@/components/sidebar/DetailClub.vue";
import LeaderboardClub from "@/components/list/LeaderboardClub.vue";
import MembersClub from "@/components/list/MembersClub.vue";
import ActivityClub from "@/components/list/ActivityClub.vue";
import PostClub from "@/components/list/PostClub.vue";
import JoinClub from "@/components/modal/JoinClub.vue";
import Swal from "sweetalert2";
import config from "@/ConfigProvider.js";

export default {
  metaInfo() {
    return {
      title: "Club " + this.currentClub.name,
      titleTemplate: "Devcode - %s",
    };
  },
  name: "DetailClub",
  components: {
    SidebarDetailClub,
    CBox,
    CText,
    CImage,
    CLink,
    LeaderboardClub,
    MembersClub,
    ActivityClub,
    PostClub,
    JoinClub,
    Loading,
    CAlert,
    CAlertIcon,
  },
  data() {
    return {
      showTable: 1,
      isModalOpen: false,
      isLoading: true,
      showAlert: true,
      logo: "",
      urlGethired: config.value("GETHIRED_WEB_URL"),
      isUrlCopied: false,
      errorMessage: "",
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    currentClub() {
      return this.$store.getters.currentClub || [];
    },
    currentClubMembers() {
      return this.$store.getters.currentClubMembers || [];
    },
    currentUrl() {
      return this.$route.fullPath;
    },
    currentSlug() {
      return this.$route.params.slug;
    },
    splitDescription() {
      return this.currentClub.description.split("\n");
    },
    splitRule() {
      return this.currentClub.rule.split("\n");
    },
  },
  async mounted() {
    try {
      const urlParts = this.$route.fullPath.split("/");
      const lastPart = urlParts[urlParts.length - 1];

      if (lastPart === "activity") {
        this.showTable = 1;
      } else if (lastPart === "leaderboard") {
        this.showTable = 2;
      } else if (lastPart === "members") {
        this.showTable = 3;
      } else if (lastPart === "posts") {
        this.showTable = 4;
      }
      await this.getData();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  methods: {
    handleLogin() {
      window.location.href =
        config.value("GETHIRED_WEB_URL") +
        `/signin?next=${window.location.href}`;
    },
    async getData() {
      try {
        await this.$store.dispatch(GET_CLUB_DETAIL, {
          slug: this.$route.params.slug,
        });
        await this.$store.dispatch(GET_CLUB_MEMBERS, {
          slug: this.$route.params.slug,
          data: {
            sortBy: "is_admin",
            sort: "DESC",
            limit: 20,
          },
        });
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.isLoading = false;
          this.errorMessage = error.response.data.message;
          console.error(`Error 400: ${this.errorMessage}`);
          Swal.fire({
            title: "Oops!",
            text: "You don't have access to this page!",
            icon: "error",
          }).then(() => {
            window.history.back();
          });
        }
      } finally {
        this.isLoading = false;
      }
    },
    async handleLeave() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, leave!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            let params = {
              slug: this.$route.params.slug,
            };
            await this.$store.dispatch(POST_CLUB_LEAVE_MEMBER, params);
            Swal.fire({
              title: "Leaved club!",
              text: "You has been leaved the club.",
              icon: "success",
            });
            await this.getData();
          } catch (error) {
            console.error("Error leaving club:", error);
            Swal.fire({
              title: "Error",
              text: "An error occurred while leaving the club.",
              icon: "error",
            });
          }
        }
      });
    },
    openModal() {
      this.isModalOpen = true;
    },
    async closeModal() {
      this.isModalOpen = false;
      await this.getData();
    },
    hideAlert() {
      this.showAlert = false;
    },
    checkType(type) {
      return type === "komunitas"
        ? "Community"
        : type === "tim lomba"
        ? "Event"
        : type === "kampus"
        ? "University"
        : "Company";
    },
  },
};
</script>
