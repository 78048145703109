var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CBox',[_c('Loading',{attrs:{"active":_vm.isLoadingComment}}),_c('CBox',{attrs:{"d":"flex","justifyContent":"space-between","alignItems":"center","mb":"16px"}},[_c('CText',{attrs:{"fontSize":"18px","fontStyle":"normal","fontWeight":"700","color":"#31373E"}},[_vm._v("Club Posts")]),(
        !_vm.currentUser.urole_id &&
        _vm.currentClub.is_joined === 1 &&
        _vm.currentClub.role !== 'member'
      )?_c('CLink',{attrs:{"href":`${_vm.currentUrl}/new`,"color":"main","d":"inline-block","_hover":{ textDecoration: 'none', opacity: 0.8 },"ml":"2"}},[_c('CText',{attrs:{"bg":"bgButtonClub","color":"white","w":"fit-content","px":"10px","py":"3px","borderRadius":"5","d":"flex","justifyContent":"center","fontSize":"11px","alignItems":"center","fontWeight":"600","lineHeight":"26px"}},[_vm._v(" Create Post")])],1):_vm._e()],1),(_vm.clubPosts.data && _vm.clubPosts.data.length > 0 && !_vm.isLoading)?_c('CBox',[_c('ModalComments',{attrs:{"isOpen":_vm.currentCommentModalStatus,"result_id":_vm.result_id,"title":`Comments for ${_vm.titleComment}`,"comments":_vm.currentComments,"user_suggestions":_vm.currentUserSuggestions,"hashtag_suggestions":_vm.currentHashtagSuggestions,"type":'POST'},on:{"updateData":_vm.updateData}}),_c('CBox',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeDropdown),expression:"closeDropdown"}],attrs:{"p":"0","py":"8px","mt":"-8px"}},_vm._l((_vm.clubPosts.data),function(v,i){return _c('CBox',{key:i,attrs:{"alignItems":"center","mb":"8px","shadow":"sm","rounded":"md","p":"16px"}},[_c('CBox',{attrs:{"d":"flex","justifyContent":"space-between"}},[_c('CBox',{attrs:{"d":"flex","alignItems":"center","borderBottom":"1px solid var(--Neutral-Light-Gray, #EFF1F5)","pb":"8px"}},[_c('CAvatar',{attrs:{"w":"35px","h":"35px","mr":"12px","name":v.user.emp_full_name.split(' ')[0],"_hover":{ opacity: '0.8' },"cursor":"pointer"},nativeOn:{"click":function($event){return _vm.$router.push({
                  name: 'Profile',
                  params: {
                    emp_id: v.emp_id,
                  },
                })}}}),_c('CBox',[_c('CText',{attrs:{"fontSize":"14px","fontWeight":"600","cursor":"pointer","_hover":{ opacity: '0.8' }},nativeOn:{"click":function($event){return _vm.$router.push({
                    name: 'Profile',
                    params: {
                      emp_id: v.emp_id,
                    },
                  })}}},[_vm._v(_vm._s(v.emp_id === _vm.currentUser.emp_id ? "You" : v.user.emp_full_name))]),_c('CBox',{attrs:{"d":"flex","alignItems":"center"}},[_c('CText',{attrs:{"fontSize":"11px","mr":"4px"}},[_vm._v(_vm._s(_vm.formatDate(v.update_at ? v.update_at : v.created_at)))]),_c('CText',{attrs:{"fontSize":"10","fontWeight":"400"}},[_vm._v(_vm._s(v.is_edited ? "Edited" : ""))])],1)],1)],1),(_vm.current_user)?_c('CBox',{attrs:{"position":"relative"}},[_c('CBox',{attrs:{"w":"fit-content","h":"fit-content","bg":"transparent","_hover":{ bg: 'transparent' },"_focus":{ outline: 0, boxShadow: 'none', border: 0 },"_active":{ bg: 'transparent' },"cursor":"pointer"},on:{"click":function($event){return _vm.toggleDropdown(i)}}},[_c('ButtonIcon',{attrs:{"icon":"ic-menu-solid.svg"}})],1),_c('CBox',{directives:[{name:"show",rawName:"v-show",value:(_vm.dropdownStatus[i]),expression:"dropdownStatus[i]"}],attrs:{"position":"absolute","top":"20px","left":"50%","transform":"translateX(-50%)","mr":"4px","backgroundColor":"white","boxShadow":"0px 4px 8px rgba(0, 0, 0, 0.1)","py":"8px","borderRadius":"4px","w":"120px"}},[(v.user.emp_id === _vm.current_user.emp_id)?[_c('CBox',{attrs:{"as":"a","href":_vm.currentUrl + '/' + v.id + '/edit',"d":"block","minH":"32px","py":"4px","px":"16px","w":"full","cursor":"pointer","_hover":{
                    opacity: '0.8',
                    backgroundColor: 'var(--colors-gray-100)',
                    outline: '2px solid transparent',
                  }}},[_vm._v("Edit")]),_c('CBox',{attrs:{"min-height":"32px","py":"4px","px":"16px","w":"full","cursor":"pointer","_hover":{
                    opacity: '0.8',
                    backgroundColor: 'var(--colors-gray-100)',
                    outline: '2px solid transparent',
                  }},nativeOn:{"click":function($event){return _vm.deleteData(v.id, v.title)}}},[_vm._v("Delete")])]:[_c('CBox',{attrs:{"minH":"32px","px":"16px","w":"full","cursor":"pointer","_hover":{
                    opacity: '0.8',
                    backgroundColor: 'var(--colors-gray-100)',
                    outline: '2px solid transparent',
                  },"py":"4px"}},[_vm._v("Report")])]],2)],1):_vm._e()],1),_c('CBox',{attrs:{"px":['10px', '40px', '40px', '40px'],"py":"8px","cursor":"pointer","_hover":{ opacity: '0.8' }},nativeOn:{"click":function($event){return _vm.redirectToDetailPost(v.id)}}},[_c('CText',{attrs:{"fontWeight":"600","fontSize":"18px"}},[_vm._v(_vm._s(v.title))]),_c('CText',{attrs:{"py":"2px","mb":"8px","fontSize":"14px","fontWeight":"400","lineHeight":"26px","white-space":"pre-line"},domProps:{"innerHTML":_vm._s(_vm.formattedCaption(v.description))}}),(v.photos && v.photos.length > 0)?_c('CBox',{attrs:{"white-space":"nowrap","position":"relative","w":['200px', '500px', '500px', '500px'],"h":['200px', '500px', '500px', '500px'],"mx":"auto"}},[_vm._l((v.photos),function(photo,i){return _c('CBox',{key:i,attrs:{"position":"absolute","top":"0","left":"0","w":"100%","h":"100%","transition":"transform 0.3s ease-in-out","hidden":(!_vm.activeImages[v.id] === false && _vm.activeImages[v.id] !== i) ||
                (!_vm.activeImages[v.id] === true && i !== 0)}},[_c('CImage',{attrs:{"src":photo,"w":"full","h":"full","object-fit":"cover"}})],1)}),_c('CBox',{attrs:{"position":"absolute","bottom":"8px","left":"50%","transform":"translateX(-50%)","display":"flex"}},_vm._l((v.photos),function(photo,i){return _c('CBox',{key:i,attrs:{"w":"8px","h":"8px","bg":"white","borderRadius":"100%","mx":"2px","opacity":(!_vm.activeImages[v.id] && i === 0) || _vm.activeImages[v.id] === i
                    ? '1'
                    : '0.5',"cursor":"pointer"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.setActiveImage(v.id, i)}}})}),1),(v.photos.length > 1)?_c('CButton',{attrs:{"position":"absolute","borderRadius":"full","top":"50%","right":"0","transform":"translateY(-50%)","_hover":{ opacity: '1' },"opacity":"0.5","color":"black","mr":"4px","size":"0","w":['24px', '34px', '34px', '34px'],"h":['24px', '34px', '34px', '34px']},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.nextPhoto(v.id, v.photos.length)}}},[_c('svg',{staticClass:"h-6 w-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M9 5l7 7-7 7"}})])]):_vm._e(),(v.photos.length > 1)?_c('CButton',{attrs:{"position":"absolute","borderRadius":"full","top":"50%","left":"0","transform":"translateY(-50%)","_hover":{ opacity: '1' },"opacity":"0.5","color":"black","ml":"4px","size":"0","w":['24px', '34px', '34px', '34px'],"h":['24px', '34px', '34px', '34px']},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.prevPhoto(v.id, v.photos.length)}}},[_c('svg',{staticClass:"h-6 w-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M15 19l-7-7 7-7"}})])]):_vm._e()],2):_vm._e()],1),_c('CBox',{attrs:{"d":"flex","justifyContent":"space-between","alignItems":"center","py":"16px","borderBottom":"1px solid var(--Neutral-Light-Gray, #EFF1F5)"}},[(v.user_liked && v.user_liked.length === 1)?_c('CBox',[_c('CText',{attrs:{"d":"inline-block","w":"fit-content","fontSize":"12px","fontWeight":"600","_hover":{ opacity: '0.8' },"mr":"4px","cursor":"pointer"},nativeOn:{"click":function($event){return _vm.$router.push({
                  name: 'Profile',
                  params: {
                    emp_id: v.user_liked[0].emp_id,
                  },
                })}}},[_vm._v(" "+_vm._s(v.user_liked[0].emp_full_name.split(" ")[0])+" ")]),_c('CText',{attrs:{"d":"inline-block","fontSize":"12px","fontWeight":"400"}},[_vm._v(" liked this post ")])],1):_vm._e(),(v.user_liked && v.user_liked.length === 2)?_c('CBox',[_c('CText',{attrs:{"d":"inline-block","w":"fit-content","fontSize":"12px","fontWeight":"600","_hover":{ opacity: '0.8' },"cursor":"pointer","mr":"4px"},nativeOn:{"click":function($event){return _vm.$router.push({
                  name: 'Profile',
                  params: {
                    emp_id: v.user_liked[0].emp_id,
                  },
                })}}},[_vm._v(" "+_vm._s(v.user_liked[0].emp_full_name.split(" ")[0])+" ")]),_c('CText',{attrs:{"fontWeight":"400","fontSize":"12px","d":"inline-block","mr":"4px"}},[_vm._v(" and ")]),_c('CText',{attrs:{"d":"inline-block","w":"fit-content","fontSize":"12px","fontWeight":"600","_hover":{ opacity: '0.8' },"cursor":"pointer","mr":"4px"},nativeOn:{"click":function($event){return _vm.$router.push({
                  name: 'Profile',
                  params: {
                    emp_id: v.user_liked[1].emp_id,
                  },
                })}}},[_vm._v(" "+_vm._s(v.user_liked[1].emp_full_name.split(" ")[0])+" ")]),_c('CText',{attrs:{"d":"inline-block","fontSize":"12px","fontWeight":"400"}},[_vm._v(" liked this post")])],1):_vm._e(),(v.user_liked && v.user_liked.length === 3)?_c('CBox',[_c('CText',{attrs:{"d":"inline-block","w":"fit-content","fontSize":"12px","fontWeight":"600","_hover":{ opacity: '0.8' },"cursor":"pointer"},nativeOn:{"click":function($event){return _vm.$router.push({
                  name: 'Profile',
                  params: {
                    emp_id: v.user_liked[0].emp_id,
                  },
                })}}},[_vm._v(" "+_vm._s(v.user_liked[0].emp_full_name.split(" ")[0])+" ")]),_c('CText',{attrs:{"fontWeight":"400","fontSize":"12px","d":"inline-block","mr":"4px"}},[_vm._v(" , ")]),_c('CText',{attrs:{"d":"inline-block","w":"fit-content","fontSize":"12px","fontWeight":"600","_hover":{ opacity: '0.8' },"cursor":"pointer"},nativeOn:{"click":function($event){return _vm.$router.push({
                  name: 'Profile',
                  params: {
                    emp_id: v.user_liked[1].emp_id,
                  },
                })}}},[_vm._v(" "+_vm._s(v.user_liked[1].emp_full_name.split(" ")[0])+" ")]),_c('CText',{attrs:{"d":"inline-block","fontSize":"12px","fontWeight":"400","mr":"4px"}},[_vm._v(" , and")]),_c('CText',{attrs:{"d":"inline-block","w":"fit-content","fontSize":"12px","fontWeight":"600","_hover":{ opacity: '0.8' },"cursor":"pointer","mr":"4px"},nativeOn:{"click":function($event){return _vm.$router.push({
                  name: 'Profile',
                  params: {
                    emp_id: v.user_liked[2].emp_id,
                  },
                })}}},[_vm._v(" "+_vm._s(v.user_liked[2].emp_full_name.split(" ")[0])+" ")]),_c('CText',{attrs:{"d":"inline-block","fontSize":"12px","fontWeight":"400"}},[_vm._v(" liked this post")])],1):_vm._e(),(v.user_liked && v.user_liked.length > 3)?_c('CBox',[_c('CText',{attrs:{"d":"inline-block","w":"fit-content","fontSize":"12px","fontWeight":"600","_hover":{ opacity: '0.8' },"cursor":"pointer"},nativeOn:{"click":function($event){return _vm.$router.push({
                  name: 'Profile',
                  params: {
                    emp_id: 3434,
                  },
                })}}},[_vm._v(" "+_vm._s(v.user_liked[0].emp_full_name.split(" ")[0])+" ")]),_c('CText',{attrs:{"fontWeight":"400","fontSize":"12px","d":"inline-block","mr":"4px"}},[_vm._v(" , ")]),_c('CText',{attrs:{"d":"inline-block","w":"fit-content","fontSize":"12px","fontWeight":"600","_hover":{ opacity: '0.8' },"cursor":"pointer"},nativeOn:{"click":function($event){return _vm.$router.push({
                  name: 'Profile',
                  params: {
                    emp_id: 3434,
                  },
                })}}},[_vm._v(" "+_vm._s(v.user_liked[1].emp_full_name.split(" ")[0])+" ")]),_c('CText',{attrs:{"d":"inline-block","fontSize":"12px","fontWeight":"400","mr":"4px"}},[_vm._v(" , and")]),_c('CText',{attrs:{"d":"inline-block","fontSize":"12px","fontWeight":"600","mr":"4px"}},[_vm._v(" "+_vm._s(v.total_like - 2)+" Others")]),_c('CText',{attrs:{"d":"inline-block","fontSize":"12px","fontWeight":"400"}},[_vm._v(" liked this post")])],1):_vm._e(),_c('CBox',{attrs:{"ml":"auto","d":"flex","justifyContent":"space-between","alignItems":"center"}},[_c('CBox',{key:v.id,attrs:{"cursor":"pointer","_hover":{ opacity: '1' },"d":"flex","flexDirection":"column","alignItems":"center"},nativeOn:{"click":function($event){_vm.postResultLike(v.id);
                $event.stopPropagation();}}},[_c('CImage',{attrs:{"src":v.is_liked === 1
                    ? require('@/assets/icon/icon-heart-solid.svg')
                    : require('@/assets/icon/icon-heart-regular.svg'),"w":"24px","h":"24px","mr":"16px"}}),_c('CText',{attrs:{"fontSize":"10px","color":"#ACACAC","mr":"16px","mt":"4px"}},[_vm._v(" "+_vm._s(v.total_like)+" ")])],1),_c('CBox',{attrs:{"cursor":"pointer","_hover":{ opacity: '1' },"d":"flex","flexDirection":"column","alignItems":"center"},nativeOn:{"click":function($event){_vm.showCommentsModal(
                  v.id,
                  v.user.emp_full_name,
                  _vm.truncateText(v.title, 20)
                );
                $event.stopPropagation();}}},[_c('CImage',{attrs:{"src":require('@/assets/icon/icon-comment-regular.svg'),"w":"24px","h":"24px"}}),_c('CText',{attrs:{"fontSize":"10px","color":"#ACACAC","mt":"4px"}},[_vm._v(" "+_vm._s(v.total_comment)+" ")])],1)],1)],1),(v.user_commented.length > 0)?_c('CBox',_vm._l((v.user_commented),function(comment,index){return _c('CardCommentPrevList',{key:index,attrs:{"index":index,"id":comment.id,"result_id":comment.content_id,"sender_id":comment.emp_id.toString(),"sender":comment.user.emp_full_name,"body":comment.body,"isLikedByUser":comment.is_liked,"likes":comment.total_like,"current_user":_vm.currentUser,"created_at":comment.created_at,"updated_at":comment.updated_at,"type":'POST'},on:{"updateData":_vm.updateData}})}),1):_vm._e()],1)}),1)],1):_c('CBox',[_c('CText',[_vm._v(_vm._s(_vm.isLoading ? "Loading..." : "There is no post yet."))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }