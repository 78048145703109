<template>
  <CBox>
    <RequestMember
      v-if="
        currentClubRequests &&
        currentClubRequests.data &&
        currentClubRequests.data.length &&
        !isLoading
      "
      @acceptMember="acceptMember"
    />
    <CText
      fontSize="18px"
      fontStyle="normal"
      fontWeight="700"
      color="#31373E"
      mb="16px"
      >Admins</CText
    >
    <CBox
      mb="32px"
      v-for="(v, i) in currentClubMembers.data.admin"
      :key="i + '1'"
    >
      <CBox v-if="v.is_admin === true">
        <CBox
          d="flex"
          alignItems="center"
          justifyContent="space-between"
          px="16px"
          pb="8px"
          borderBottom="1px solid var(--Neutral-Light-Gray, #EFF1F5)"
        >
          <CBox d="flex" alignItems="center">
            <CAvatar
              w="35px"
              h="35px"
              mr="16px"
              :name="v.user.emp_full_name.split(' ')[0]"
              :_hover="{ opacity: '0.8' }"
              cursor="pointer"
              @click.native="
                $router.push({
                  name: 'Profile',
                  params: {
                    emp_id: v.emp_id,
                  },
                })
              "
            ></CAvatar>
            <CBox
              d="flex"
              :flexDirection="['column', 'row', 'row', 'row']"
              :alignItems="['none', 'center', 'center', 'center']"
            >
              <CText
                textAlign="left"
                mr="8px"
                :mb="['2', '0', '0', '0']"
                fontSize="14px"
                w="fit-content"
                :_hover="{ opacity: '0.8' }"
                cursor="pointer"
                @click.native="
                  $router.push({
                    name: 'Profile',
                    params: {
                      emp_id: v.emp_id,
                    },
                  })
                "
                >{{
                  v.emp_id === currentUser.emp_id ? "You" : v.user.emp_full_name
                }}</CText
              >
              <CText
                fontSize="12px"
                fontWeight="600"
                :color="
                  v.is_owner === 1
                    ? '#29AAFE'
                    : v.is_admin
                    ? '#FFA602'
                    : '#31373E'
                "
                :bg="
                  v.is_owner === 1
                    ? '#F0F8FF'
                    : v.is_admin
                    ? '#FFFCEA'
                    : '#EFF1F5'
                "
                px="8px"
                w="fit-content"
                :mr="['0', '8px', '8px', '8px']"
                >{{
                  v.is_owner === 1 ? "Owner" : v.is_admin ? "Admin" : "Member"
                }}</CText
              >
              <CBox>
                <CBox v-if="isOwner" d="flex" alignItems="center" mt="8px">
                  <CButton
                    v-if="v.emp_id !== currentUser.emp_id"
                    :d="['block', 'none', 'none', 'none']"
                    w="fit-content"
                    fontSize="12px"
                    fontWeight="600"
                    color="bgButtonClub"
                    :_hover="{ opacity: '0.8' }"
                    cursor="pointer"
                    @click.native="handlePromotion(v.emp_id, v.is_admin)"
                    :mr="['16px', '8px', '8px', '8px']"
                    :size="['none', 'sm', 'sm', 'sm']"
                    bg="none"
                    >Revoke Admin</CButton
                  >
                  <CButton
                    v-if="v.emp_id !== currentUser.emp_id"
                    :d="['block', 'none', 'none', 'none']"
                    w="fit-content"
                    fontSize="12px"
                    fontWeight="600"
                    color="#E71B1B"
                    :_hover="{ opacity: '0.8' }"
                    cursor="pointer"
                    @click.native="handleRemove(v.emp_id)"
                    :size="['none', 'sm', 'sm', 'sm']"
                    bg="none"
                    >Remove</CButton
                  >
                </CBox>
              </CBox>
            </CBox>
          </CBox>
          <CBox v-if="isOwner" d="flex" mt="8px">
            <CButton
              v-if="v.emp_id !== currentUser.emp_id"
              :d="['none', 'block', 'block', 'block']"
              w="fit-content"
              fontSize="12px"
              fontWeight="600"
              color="bgButtonClub"
              :_hover="{ opacity: '0.8' }"
              cursor="pointer"
              @click.native="handlePromotion(v.emp_id, v.is_admin)"
              mr="8px"
              size="sm"
              bg="none"
              >Revoke Admin</CButton
            >
            <CButton
              v-if="v.emp_id !== currentUser.emp_id"
              :d="['none', 'block', 'block', 'block']"
              w="fit-content"
              fontSize="12px"
              fontWeight="600"
              color="#E71B1B"
              :_hover="{ opacity: '0.8' }"
              cursor="pointer"
              @click.native="handleRemove(v.emp_id)"
              size="sm"
              bg="none"
              >Remove</CButton
            >
          </CBox>
        </CBox>
      </CBox>
    </CBox>
    <CText
      fontSize="18px"
      fontStyle="normal"
      fontWeight="700"
      color="#31373E"
      mb="16px"
      >Members</CText
    >
    <CBox
      v-if="
        currentClubMembers.data.member &&
        currentClubMembers.data.member.length > 0
      "
    >
      <CBox
        mb="32px"
        v-for="(v, i) in currentClubMembers.data.member"
        :key="i + '2'"
      >
        <CBox
          d="flex"
          alignItems="center"
          justifyContent="space-between"
          px="16px"
          pb="8px"
          borderBottom="1px solid var(--Neutral-Light-Gray, #EFF1F5)"
        >
          <CBox d="flex" alignItems="center">
            <CAvatar
              w="35px"
              h="35px"
              mr="16px"
              :name="v.user.emp_full_name.split(' ')[0]"
              :_hover="{ opacity: '0.8' }"
              cursor="pointer"
              @click.native="
                $router.push({
                  name: 'Profile',
                  params: {
                    emp_id: v.emp_id,
                  },
                })
              "
            ></CAvatar>
            <CBox
              d="flex"
              :flexDirection="['column', 'row', 'row', 'row']"
              :alignItems="['none', 'center', 'center', 'center']"
            >
              <CText
                fontSize="14px"
                textAlign="left"
                mr="4"
                :mb="['2', '0', '0', '0']"
                w="fit-content"
                :_hover="{ opacity: '0.8' }"
                cursor="pointer"
                @click.native="
                  $router.push({
                    name: 'Profile',
                    params: {
                      emp_id: v.emp_id,
                    },
                  })
                "
                >{{
                  v.emp_id === currentUser.emp_id ? "You" : v.user.emp_full_name
                }}</CText
              >
              <CText
                fontSize="12px"
                fontWeight="600"
                :color="
                  v.is_owner === 1
                    ? '#29AAFE'
                    : v.is_admin
                    ? '#FFA602'
                    : '#31373E'
                "
                :bg="
                  v.is_owner === 1
                    ? '#F0F8FF'
                    : v.is_admin
                    ? '#FFFCEA'
                    : '#EFF1F5'
                "
                px="8px"
                w="fit-content"
                :mr="['0', '8px', '8px', '8px']"
                >{{
                  v.is_owner === 1 ? "Owner" : v.is_admin ? "Admin" : "Member"
                }}</CText
              >
              <CBox>
                <CBox v-if="isOwner" d="flex" alignItems="center" mt="8px">
                  <CButton
                    v-if="v.emp_id !== currentUser.emp_id"
                    :d="['block', 'none', 'none', 'none']"
                    w="fit-content"
                    fontSize="12px"
                    fontWeight="600"
                    color="bgButtonClub"
                    :_hover="{ opacity: '0.8' }"
                    cursor="pointer"
                    mr="16px"
                    @click.native="handlePromotion(v.emp_id, v.is_admin)"
                    size="none"
                    bg="none"
                    >Make Admin</CButton
                  >
                  <CButton
                    v-if="v.emp_id !== currentUser.emp_id"
                    :d="['block', 'none', 'none', 'none']"
                    w="fit-content"
                    fontSize="12px"
                    fontWeight="600"
                    color="#E71B1B"
                    :_hover="{ opacity: '0.8' }"
                    cursor="pointer"
                    @click.native="handleRemove(v.emp_id)"
                    size="none"
                    bg="none"
                    >Remove</CButton
                  >
                </CBox>
                <CBox v-else-if="isAdmin" d="flex">
                  <CButton
                    v-if="
                      v.emp_id !== currentUser.emp_id &&
                      v.is_owner !== 1 &&
                      v.is_admin !== true
                    "
                    :d="['block', 'none', 'none', 'none']"
                    w="fit-content"
                    fontSize="12px"
                    fontWeight="600"
                    color="bgButtonClub"
                    :_hover="{ opacity: '0.8' }"
                    cursor="pointer"
                    :mr="['16px', '8px', '8px', '8px']"
                    @click.native="handlePromotion(v.emp_id, v.is_admin)"
                    size="sm"
                    bg="none"
                    >Make Admin</CButton
                  >
                  <CButton
                    v-if="
                      v.emp_id !== currentUser.emp_id &&
                      v.is_owner !== 1 &&
                      v.is_admin !== true
                    "
                    :d="['block', 'none', 'none', 'none']"
                    w="fit-content"
                    fontSize="12px"
                    fontWeight="600"
                    color="#E71B1B"
                    :_hover="{ opacity: '0.8' }"
                    cursor="pointer"
                    @click.native="handleRemove(v.emp_id)"
                    size="sm"
                    bg="none"
                    >Remove</CButton
                  >
                </CBox>
              </CBox>
            </CBox>
          </CBox>
          <CBox v-if="isOwner" d="flex">
            <CButton
              v-if="v.emp_id !== currentUser.emp_id"
              :d="['none', 'block', 'block', 'block']"
              w="fit-content"
              fontSize="12px"
              fontWeight="600"
              color="bgButtonClub"
              :_hover="{ opacity: '0.8' }"
              cursor="pointer"
              mr="8px"
              @click.native="handlePromotion(v.emp_id, v.is_admin)"
              size="sm"
              bg="none"
              >Make Admin</CButton
            >
            <CButton
              v-if="v.emp_id !== currentUser.emp_id"
              :d="['none', 'block', 'block', 'block']"
              w="fit-content"
              fontSize="12px"
              fontWeight="600"
              color="#E71B1B"
              :_hover="{ opacity: '0.8' }"
              cursor="pointer"
              @click.native="handleRemove(v.emp_id)"
              size="sm"
              bg="none"
              >Remove</CButton
            >
          </CBox>
          <CBox v-if="isAdmin" d="flex">
            <CButton
              v-if="
                v.emp_id !== currentUser.emp_id &&
                v.is_owner !== 1 &&
                v.is_admin !== true
              "
              :d="['none', 'block', 'block', 'block']"
              w="fit-content"
              fontSize="12px"
              fontWeight="600"
              color="bgButtonClub"
              :_hover="{ opacity: '0.8' }"
              cursor="pointer"
              mr="8px"
              @click.native="handlePromotion(v.emp_id, v.is_admin)"
              size="sm"
              bg="none"
              >Make Admin</CButton
            >

            <CButton
              v-if="
                v.emp_id !== currentUser.emp_id &&
                v.is_owner !== 1 &&
                v.is_admin !== true
              "
              :d="['none', 'block', 'block', 'block']"
              w="fit-content"
              fontSize="12px"
              fontWeight="600"
              color="#E71B1B"
              :_hover="{ opacity: '0.8' }"
              cursor="pointer"
              @click.native="handleRemove(v.emp_id)"
              size="sm"
              bg="none"
              >Remove</CButton
            >
          </CBox>
        </CBox>
      </CBox>
    </CBox>
    <CBox v-else>
      <CText>{{
        isLoading
          ? "Loading..."
          : "There are no active members in this club yet."
      }}</CText>
    </CBox>
  </CBox>
</template>

<script>
import { CBox, CText, CAvatar } from "@chakra-ui/vue";
import {
  GET_CLUB_MEMBERS,
  GET_CLUB_REQUESTS,
  POST_CLUB_MEMBER_PROMOTION,
  POST_CLUB_MEMBER_DEMOTION,
  POST_CLUB_REMOVE_MEMBER,
} from "@/store/club.module";
import RequestMember from "@/components/list/RequestMember.vue";
import Swal from "sweetalert2";

export default {
  name: "DetailClub",
  components: {
    CBox,
    CText,
    RequestMember,
    CAvatar,
  },
  props: {
    isRequest: Boolean,
    isOwner: Boolean,
    isAdmin: Boolean,
  },
  data() {
    return {
      isLoading: true,
      currentPage: 1,
      memberName: "",
      memberId: 0,
      isDataAdmin: false,
      isModalOpen: false,
      isEmptyMember: true,
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser || [];
    },
    currentClub() {
      return this.$store.getters.currentClub || [];
    },
    currentClubRequests() {
      return this.$store.getters.currentClubRequests || [];
    },
    currentClubMembers() {
      return this.$store.getters.currentClubMembers || [];
    },
    totalPages() {
      return Math.ceil(
        this.currentClubMembers.meta.total / this.currentClubMembers.meta.limit
      );
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        const params = {
          slug: this.$route.params.slug,
          data: {
            page: this.currentPage,
            limit: 100,
            sort: "DESC",
          },
        };

        const paramsRequest = {
          slug: this.$route.params.slug,
          data: {
            page: this.currentPage,
            limit: 10,
            sortBy: "update_at",
            status: "pending",
          },
        };

        await this.$store.dispatch(GET_CLUB_MEMBERS, params);
        if (
          this.currentClub.role === "owner" ||
          this.currentClub.role === "admin"
        )
          await this.$store.dispatch(GET_CLUB_REQUESTS, paramsRequest);
        this.isEmptyMember = true;
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },
    handlePromotion(id, isAdmin) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: `Yes, make ${isAdmin ? "member" : "admin"}!`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let params = {
            slug: this.$route.params.slug,
            memberid: id,
          };

          try {
            if (!isAdmin) {
              await this.$store.dispatch(POST_CLUB_MEMBER_PROMOTION, params);
              Swal.fire({
                title: "Admin club!",
                text: "You has been make admin club.",
                icon: "success",
              });
              await this.getData();
            } else {
              await this.$store.dispatch(POST_CLUB_MEMBER_DEMOTION, params);
              Swal.fire({
                title: "Member club!",
                text: "You has been make member club.",
                icon: "success",
              });
              await this.getData();
            }
          } catch (error) {
            console.error(`Error make ${isAdmin ? "member" : "admin"}:`, error);
            Swal.fire({
              title: "Error",
              text: `An error occurred while making ${
                isAdmin ? "member" : "admin"
              }.`,
              icon: "error",
            });
          }
        }
      });
    },
    async handleRemove(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, remove!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let params = {
            slug: this.$route.params.slug,
            memberid: id,
          };

          try {
            await this.$store.dispatch(POST_CLUB_REMOVE_MEMBER, params);
            Swal.fire({
              title: "Admin club!",
              text: "You have successfully removed the member.",
              icon: "success",
            });
            await this.getData();
          } catch (error) {
            console.error("Error removeing member:", error);
            Swal.fire({
              title: "Error",
              text: "An error occurred while removeing the member.",
              icon: "error",
            });
          }
        }
      });
    },
    setCurrentPage(page) {
      this.currentPage = page;
      this.getData();
    },
    async acceptMember() {
      await this.getData();
    },
  },
};
</script>
