var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CBox',[_c('Loading',{attrs:{"active":_vm.isLoadingComment}}),(
      _vm.currentClubActivities.data &&
      _vm.currentClubActivities.data.length > 0 &&
      !_vm.isLoading
    )?_c('CBox',[_c('ModalComments',{attrs:{"isOpen":_vm.currentCommentModalStatus,"result_id":_vm.result_id,"title":`Comments for ${_vm.titleComment}`,"comments":_vm.currentComments,"user_suggestions":_vm.currentUserSuggestions,"hashtag_suggestions":_vm.currentHashtagSuggestions,"type":'RESULT'},on:{"updateData":_vm.updateData}}),_c('CBox',{attrs:{"p":"0","py":"8px","mt":"-8px"}},_vm._l((_vm.currentClubActivities.data),function(v){return _c('CBox',{key:v.id,attrs:{"alignItems":"center","mb":"2","shadow":"sm","rounded":"md","p":"16px"}},[_c('CBox',[_c('CBox',{attrs:{"d":"flex","alignItems":['none', 'center', 'center', 'center'],"pb":"16px","borderBottom":"1px solid var(--Neutral-Light-Gray, #EFF1F5)","flexDirection":['column', 'row', 'row', 'row']}},[_c('CBox',{attrs:{"mr":"auto","mb":['8px', '0', '0', '0']}},[_c('CText',{attrs:{"fontSize":"16px","fontWeight":"600","textTransform":"capitalize","cursor":"pointer","_hover":{ opacity: '0.8' }},nativeOn:{"click":function($event){return _vm.directLinkChallenge(v.app_type.slug)}}},[_vm._v(" "+_vm._s(_vm.truncateText(v.app_type.name, 200))+" ")]),(
                  _vm.mergedParticipants[v.app_type.id] &&
                  Object.values(_vm.mergedParticipants[v.app_type.id]) &&
                  Object.values(_vm.mergedParticipants[v.app_type.id]).length == 1
                )?_c('CText',{attrs:{"fontSize":"11px","fontWeight":"400","textTransform":"capitalize"}},[_c('CText',{attrs:{"d":"inline-block","fontWeight":"600"}},[_c('CText',{attrs:{"d":"inline-block","w":"fit-content","_hover":{ opacity: '0.8' },"cursor":"pointer"},nativeOn:{"click":function($event){return _vm.$router.push({
                        name: 'Profile',
                        params: {
                          emp_id: v.app_type.id,
                        },
                      })}}},[_vm._v(" "+_vm._s(Object.values( _vm.mergedParticipants[v.app_type.id] )[0][0].split(" ")[0])+" ")])],1),_vm._v(" joined this challenge ")],1):(
                  _vm.mergedParticipants[v.app_type.id] &&
                  Object.values(_vm.mergedParticipants[v.app_type.id]) &&
                  Object.values(_vm.mergedParticipants[v.app_type.id]).length == 2
                )?_c('CText',{attrs:{"fontSize":"11px","fontWeight":"400","textTransform":"capitalize"}},[_c('CText',{attrs:{"d":"inline-block","fontWeight":"600"}},[_c('CText',{attrs:{"d":"inline-block","w":"fit-content","_hover":{ opacity: '0.8' },"cursor":"pointer"},nativeOn:{"click":function($event){return _vm.$router.push({
                        name: 'Profile',
                        params: {
                          emp_id: v.app_type.id,
                        },
                      })}}},[_vm._v(" "+_vm._s(Object.values( _vm.mergedParticipants[v.app_type.id] )[0][0].split(" ")[0])+" ")]),_vm._v(" and "),_c('CText',{attrs:{"d":"inline-block","w":"fit-content","_hover":{ opacity: '0.8' },"cursor":"pointer"},nativeOn:{"click":function($event){return _vm.$router.push({
                        name: 'Profile',
                        params: {
                          emp_id: v.app_type.id,
                        },
                      })}}},[_vm._v(" "+_vm._s(Object.values( _vm.mergedParticipants[v.app_type.id] )[1][0].split(" ")[0])+" ")])],1),_vm._v(" joined this challenge ")],1):(
                  _vm.mergedParticipants[v.app_type.id] &&
                  Object.values(_vm.mergedParticipants[v.app_type.id]) &&
                  Object.values(_vm.mergedParticipants[v.app_type.id]).length > 2
                )?_c('CText',{attrs:{"fontSize":"11px","fontWeight":"400","textTransform":"capitalize"}},[_c('CText',{attrs:{"d":"inline-block","fontWeight":"600"}},[_c('CText',{attrs:{"d":"inline-block","w":"fit-content"},nativeOn:{"click":function($event){return _vm.$router.push({
                        name: 'Profile',
                        params: {
                          emp_id: v.app_type.id,
                        },
                      })}}},[_vm._v(" "+_vm._s(Object.values( _vm.mergedParticipants[v.app_type.id] )[0][0].split(" ")[0])+" ")]),_vm._v(", "),_c('CText',{attrs:{"d":"inline-block","w":"fit-content","_hover":{ opacity: '0.8' },"cursor":"pointer"},nativeOn:{"click":function($event){return _vm.$router.push({
                        name: 'Profile',
                        params: {
                          emp_id: v.app_type.id,
                        },
                      })}}},[_vm._v(" "+_vm._s(Object.values( _vm.mergedParticipants[v.app_type.id] )[1][0].split(" ")[0]))])],1),_vm._v(" and "),_c('CText',{attrs:{"d":"inline-block","fontWeight":"600"}},[_vm._v(_vm._s(Object.values(_vm.mergedParticipants[v.app_type.id]).length - 2)+" Others")]),_vm._v(" joined this challenge ")],1):_vm._e()],1),_c('CButton',{attrs:{"bg":"bgButtonClub","color":"white","_hover":{ opacity: '0.8' },"fontSize":"11px","size":"0","w":"fit-content","px":"16px","py":"8px"},on:{"click":function($event){return _vm.directLinkChallenge(v.app_type.slug)}}},[_vm._v(" Join Challenge ")])],1),_vm._l((v.participant),function(participant,i){return _c('CBox',{key:i,attrs:{"d":"flex","alignItems":['none', 'center', 'center', 'center'],"py":"24px","px":"16px","borderBottom":"1px solid var(--Neutral-Light-Gray, #EFF1F5)","flexDirection":['column', 'row', 'row', 'row'],"w":"full"}},[_c('CBox',{attrs:{"d":"flex","alignItems":"center","w":['full', '70%', '70%', '70%'],"mr":['0', '16px', '16px', '16px'],"mb":['16px', '0', '0', '0']}},[_c('CAvatar',{attrs:{"w":"35px","h":"35px","mr":"12px","name":participant.user.emp_full_name.split(' ')[0],"_hover":{ opacity: '0.8' },"cursor":"pointer"},nativeOn:{"click":function($event){return _vm.$router.push({
                    name: 'Profile',
                    params: {
                      emp_id: participant.user.emp_id,
                    },
                  })}}}),_c('CBox',{attrs:{"w":"full"}},[_c('CText',{attrs:{"w":"fit-content","fontSize":"14px","fontWeight":"600","_hover":{ opacity: '0.8' },"cursor":"pointer"},nativeOn:{"click":function($event){return _vm.$router.push({
                      name: 'Profile',
                      params: {
                        emp_id: participant.user.emp_id,
                      },
                    })}}},[_vm._v(_vm._s(participant.user.emp_id === _vm.currentUser.emp_id ? "You" : participant.user.emp_full_name))]),_c('CBox',{attrs:{"d":"flex","alignItems":"center"}},[_c('CText',{attrs:{"fontSize":"11px","fontWeight":"400","mr":"8px"}},[_vm._v(" "+_vm._s(_vm.formatTimeAgo( participant.updated_at || participant.created_at ))+" ")]),_c('CText',{attrs:{"color":"main","fontSize":"20px","fontWeight":"700","mr":"4px"}},[_vm._v("+")]),_c('CText',{attrs:{"color":"main","fontSize":"16px","fontWeight":"700","mr":"4px"}},[_vm._v(_vm._s(participant.xp))]),_c('CText',{attrs:{"color":"main","fontSize":"10px","fontWeight":"400"}},[_vm._v("Exp")]),_c('CBox',{attrs:{"d":['flex', 'none', 'none', 'none'],"justifyContent":"space-between","alignItems":"center","ml":"auto","mt":"2px"}},[_c('CBox',{key:participant.result_id,attrs:{"cursor":"pointer","_hover":{ opacity: '1' },"d":"flex","flexDirection":"column","alignItems":"center","mr":"16px"},nativeOn:{"click":function($event){_vm.postResultLike(participant.result_id);
                        $event.stopPropagation();}}},[_c('CImage',{attrs:{"src":participant.is_liked === 1
                            ? require('@/assets/icon/icon-heart-solid.svg')
                            : require('@/assets/icon/icon-heart-regular.svg'),"w":"24px","h":"24px"}}),_c('CText',{attrs:{"fontSize":"10px","color":"#ACACAC","mt":"4px"}},[_vm._v(" "+_vm._s(participant.total_like)+" ")])],1),_c('CBox',{attrs:{"cursor":"pointer","_hover":{ opacity: '1' },"d":"flex","flexDirection":"column","alignItems":"center"},nativeOn:{"click":function($event){_vm.showCommentsModal(
                          participant.result_id,
                          participant.user.emp_full_name,
                          _vm.truncateText(v.app_type.name, 50)
                        );
                        $event.stopPropagation();}}},[_c('CImage',{attrs:{"src":require('@/assets/icon/icon-comment-regular.svg'),"w":"24px","h":"24px"}}),_c('CText',{attrs:{"fontSize":"10px","color":"#ACACAC","mt":"4px"}},[_vm._v(" "+_vm._s(participant.total_comment)+" ")])],1)],1)],1)],1)],1),_c('CBox',{attrs:{"d":"flex","justifyContent":"space-between","w":"full"}},[_c('CBox',{attrs:{"_hover":{ opacity: '0.8' },"cursor":"pointer","w":['30%', '20%', '20%', '20%']},nativeOn:{"click":function($event){return _vm.$router.push({
                    name: 'Detail Job',
                    params: {
                      id: participant.result_id,
                    },
                  })}}},[_c('CText',{attrs:{"fontSize":"12px","fontWeight":"400"}},[_vm._v("Tech Stack")]),_c('CBox',{attrs:{"d":"flex","alignItems":"center","mt":"4px"}},[(
                      _vm.languages.includes(
                        participant.hashtag[0].tag.name.toLowerCase()
                      )
                    )?_c('CImage',{attrs:{"src":require('@/assets/icon/icon-' +
                        participant.hashtag[0].tag.name
                          .toLowerCase()
                          .replace('#', 's') +
                        '.svg'),"alt":participant.hashtag[0].tag.name.toLowerCase(),"title":participant.hashtag[0].tag.name.toLowerCase(),"mr":"8px","w":"20px","d":"inline-block"}}):_vm._e(),_c('CText',{attrs:{"fontSize":"12px","fontWeight":"400","d":"flex","alignItems":"center","textTransform":"capitalize"}},[_vm._v(" "+_vm._s(participant.hashtag[0].tag.name ? participant.hashtag[0].tag.name : "-")+" ")])],1)],1),_c('CBox',{attrs:{"_hover":{ opacity: '0.8' },"cursor":"pointer","px":"8px"},nativeOn:{"click":function($event){return _vm.$router.push({
                    name: 'Detail Job',
                    params: {
                      id: participant.result_id,
                    },
                  })}}},[_c('CText',{attrs:{"fontSize":"12px","fontWeight":"400"}},[_vm._v("Total Durations")]),_c('CText',{attrs:{"fontSize":"16px","fontWeight":"600","d":"flex","alignItems":"center"}},[_vm._v(" "+_vm._s(_vm.formatDuration(participant.total_duration))+" "),_c('CText',{attrs:{"fontSize":"10px","fontWeight":"400","d":"inline-block","ml":"4px"}},[_vm._v("Min")])],1)],1),_c('CBox',{attrs:{"_hover":{ opacity: '0.8' },"cursor":"pointer"},nativeOn:{"click":function($event){return _vm.$router.push({
                    name: 'Detail Job',
                    params: {
                      id: participant.result_id,
                    },
                  })}}},[_c('CText',{attrs:{"fontSize":"12px","fontWeight":"400"}},[_vm._v("Total Points")]),_c('CText',{attrs:{"fontSize":"16px","fontWeight":"600","d":"flex","alignItems":"center"}},[_vm._v(" "+_vm._s(participant.total_point)+" "),_c('CText',{attrs:{"fontSize":"10px","fontWeight":"400","d":"inline-block","ml":"4px"}},[_vm._v("Point")])],1)],1),_c('CBox',{attrs:{"d":['none', 'flex', 'flex', 'flex'],"justifyContent":"space-between","alignItems":"center"}},[_c('CBox',{key:participant.result_id,attrs:{"cursor":"pointer","_hover":{ opacity: '1' },"d":"flex","flexDirection":"column","alignItems":"center"},nativeOn:{"click":function($event){_vm.postResultLike(
                      participant.result_id,
                      _vm.currentUser && _vm.currentUser.length > 0
                    );
                    $event.stopPropagation();}}},[_c('CImage',{attrs:{"src":participant.is_liked === 1
                        ? require('@/assets/icon/icon-heart-solid.svg')
                        : require('@/assets/icon/icon-heart-regular.svg'),"w":"24px","h":"24px","mr":"16px"}}),_c('CText',{attrs:{"fontSize":"10px","color":"#ACACAC","mr":"16px","mt":"4px"}},[_vm._v(" "+_vm._s(participant.total_like)+" ")])],1),_c('CBox',{attrs:{"cursor":"pointer","_hover":{ opacity: '1' },"d":"flex","flexDirection":"column","alignItems":"center"},nativeOn:{"click":function($event){_vm.showCommentsModal(
                      participant.result_id,
                      participant.user.emp_full_name,
                      _vm.truncateText(v.app_type.name, 20)
                    );
                    $event.stopPropagation();}}},[_c('CImage',{attrs:{"src":require('@/assets/icon/icon-comment-regular.svg'),"w":"24px","h":"24px"}}),_c('CText',{attrs:{"fontSize":"10px","color":"#ACACAC","mt":"4px"}},[_vm._v(" "+_vm._s(participant.total_comment)+" ")])],1)],1)],1)],1)})],2)],1)}),1)],1):_c('CBox',[_c('CText',[_vm._v(_vm._s(_vm.isLoading ? "Loading..." : "There is no activity yet."))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }