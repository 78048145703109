<template>
  <CBox>
    <Loading :active="isLoadingComment" />
    <CBox d="flex" justifyContent="space-between" alignItems="center" mb="16px">
      <CText fontSize="18px" fontStyle="normal" fontWeight="700" color="#31373E"
        >Club Posts</CText
      >
      <CLink
        v-if="
          !currentUser.urole_id &&
          currentClub.is_joined === 1 &&
          currentClub.role !== 'member'
        "
        :href="`${currentUrl}/new`"
        color="main"
        d="inline-block"
        :_hover="{ textDecoration: 'none', opacity: 0.8 }"
        ml="2"
      >
        <CText
          bg="bgButtonClub"
          color="white"
          w="fit-content"
          px="10px"
          py="3px"
          borderRadius="5"
          d="flex"
          justifyContent="center"
          fontSize="11px"
          alignItems="center"
          fontWeight="600"
          lineHeight="26px"
        >
          Create Post</CText
        >
      </CLink>
    </CBox>
    <CBox v-if="clubPosts.data && clubPosts.data.length > 0 && !isLoading">
      <ModalComments
        :isOpen="currentCommentModalStatus"
        :result_id="result_id"
        :title="`Comments for ${titleComment}`"
        :comments="currentComments"
        :user_suggestions="currentUserSuggestions"
        :hashtag_suggestions="currentHashtagSuggestions"
        :type="'POST'"
        @updateData="updateData"
      />
      <CBox p="0" py="8px" mt="-8px" v-click-outside="closeDropdown">
        <CBox
          v-for="(v, i) in clubPosts.data"
          :key="i"
          alignItems="center"
          mb="8px"
          shadow="sm"
          rounded="md"
          p="16px"
        >
          <CBox d="flex" justifyContent="space-between">
            <CBox
              d="flex"
              alignItems="center"
              borderBottom="1px solid var(--Neutral-Light-Gray, #EFF1F5)"
              pb="8px"
            >
              <CAvatar
                w="35px"
                h="35px"
                mr="12px"
                :name="v.user.emp_full_name.split(' ')[0]"
                :_hover="{ opacity: '0.8' }"
                cursor="pointer"
                @click.native="
                  $router.push({
                    name: 'Profile',
                    params: {
                      emp_id: v.emp_id,
                    },
                  })
                "
              ></CAvatar>
              <CBox>
                <CText
                  fontSize="14px"
                  fontWeight="600"
                  cursor="pointer"
                  :_hover="{ opacity: '0.8' }"
                  @click.native="
                    $router.push({
                      name: 'Profile',
                      params: {
                        emp_id: v.emp_id,
                      },
                    })
                  "
                  >{{
                    v.emp_id === currentUser.emp_id
                      ? "You"
                      : v.user.emp_full_name
                  }}</CText
                >
                <CBox d="flex" alignItems="center">
                  <CText fontSize="11px" mr="4px">{{
                    formatDate(v.update_at ? v.update_at : v.created_at)
                  }}</CText>
                  <CText fontSize="10" fontWeight="400">{{
                    v.is_edited ? "Edited" : ""
                  }}</CText>
                </CBox>
              </CBox>
            </CBox>
            <CBox v-if="current_user" position="relative">
              <CBox
                w="fit-content"
                h="fit-content"
                bg="transparent"
                :_hover="{ bg: 'transparent' }"
                :_focus="{ outline: 0, boxShadow: 'none', border: 0 }"
                :_active="{ bg: 'transparent' }"
                @click="toggleDropdown(i)"
                cursor="pointer"
              >
                <ButtonIcon icon="ic-menu-solid.svg" />
              </CBox>
              <CBox
                v-show="dropdownStatus[i]"
                position="absolute"
                top="20px"
                left="50%"
                transform="translateX(-50%)"
                mr="4px"
                backgroundColor="white"
                boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)"
                py="8px"
                borderRadius="4px"
                w="120px"
              >
                <template v-if="v.user.emp_id === current_user.emp_id">
                  <CBox
                    as="a"
                    :href="currentUrl + '/' + v.id + '/edit'"
                    d="block"
                    minH="32px"
                    py="4px"
                    px="16px"
                    w="full"
                    cursor="pointer"
                    :_hover="{
                      opacity: '0.8',
                      backgroundColor: 'var(--colors-gray-100)',
                      outline: '2px solid transparent',
                    }"
                    >Edit</CBox
                  >
                  <CBox
                    @click.native="deleteData(v.id, v.title)"
                    min-height="32px"
                    py="4px"
                    px="16px"
                    w="full"
                    cursor="pointer"
                    :_hover="{
                      opacity: '0.8',
                      backgroundColor: 'var(--colors-gray-100)',
                      outline: '2px solid transparent',
                    }"
                    >Delete</CBox
                  >
                </template>
                <template v-else>
                  <CBox
                    minH="32px"
                    px="16px"
                    w="full"
                    cursor="pointer"
                    :_hover="{
                      opacity: '0.8',
                      backgroundColor: 'var(--colors-gray-100)',
                      outline: '2px solid transparent',
                    }"
                    py="4px"
                    >Report</CBox
                  >
                </template>
              </CBox>
            </CBox>
          </CBox>
          <CBox
            :px="['10px', '40px', '40px', '40px']"
            py="8px"
            cursor="pointer"
            :_hover="{ opacity: '0.8' }"
            @click.native="redirectToDetailPost(v.id)"
          >
            <CText fontWeight="600" fontSize="18px">{{ v.title }}</CText>
            <CText
              py="2px"
              mb="8px"
              fontSize="14px"
              fontWeight="400"
              lineHeight="26px"
              white-space="pre-line"
              v-html="formattedCaption(v.description)"
            >
            </CText>
            <CBox
              v-if="v.photos && v.photos.length > 0"
              white-space="nowrap"
              position="relative"
              :w="['200px', '500px', '500px', '500px']"
              :h="['200px', '500px', '500px', '500px']"
              mx="auto"
            >
              <CBox
                v-for="(photo, i) in v.photos"
                :key="i"
                position="absolute"
                top="0"
                left="0"
                w="100%"
                h="100%"
                transition="transform 0.3s ease-in-out"
                :hidden="
                  (!activeImages[v.id] === false && activeImages[v.id] !== i) ||
                  (!activeImages[v.id] === true && i !== 0)
                "
              >
                <CImage :src="photo" w="full" h="full" object-fit="cover" />
              </CBox>
              <CBox
                position="absolute"
                bottom="8px"
                left="50%"
                transform="translateX(-50%)"
                display="flex"
              >
                <CBox
                  v-for="(photo, i) in v.photos"
                  :key="i"
                  w="8px"
                  h="8px"
                  bg="white"
                  borderRadius="100%"
                  mx="2px"
                  :opacity="
                    (!activeImages[v.id] && i === 0) || activeImages[v.id] === i
                      ? '1'
                      : '0.5'
                  "
                  @click.native.stop="setActiveImage(v.id, i)"
                  cursor="pointer"
                />
              </CBox>
              <CButton
                v-if="v.photos.length > 1"
                position="absolute"
                borderRadius="full"
                top="50%"
                right="0"
                transform="translateY(-50%)"
                :_hover="{ opacity: '1' }"
                opacity="0.5"
                color="black"
                @click.native.stop="nextPhoto(v.id, v.photos.length)"
                mr="4px"
                size="0"
                :w="['24px', '34px', '34px', '34px']"
                :h="['24px', '34px', '34px', '34px']"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </CButton>
              <CButton
                v-if="v.photos.length > 1"
                position="absolute"
                borderRadius="full"
                top="50%"
                left="0"
                transform="translateY(-50%)"
                :_hover="{ opacity: '1' }"
                opacity="0.5"
                color="black"
                @click.native.stop="prevPhoto(v.id, v.photos.length)"
                ml="4px"
                size="0"
                :w="['24px', '34px', '34px', '34px']"
                :h="['24px', '34px', '34px', '34px']"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </CButton>
            </CBox>
          </CBox>
          <CBox
            d="flex"
            justifyContent="space-between"
            alignItems="center"
            py="16px"
            borderBottom="1px solid var(--Neutral-Light-Gray, #EFF1F5)"
          >
            <CBox v-if="v.user_liked && v.user_liked.length === 1">
              <CText
                d="inline-block"
                w="fit-content"
                fontSize="12px"
                fontWeight="600"
                :_hover="{ opacity: '0.8' }"
                mr="4px"
                cursor="pointer"
                @click.native="
                  $router.push({
                    name: 'Profile',
                    params: {
                      emp_id: v.user_liked[0].emp_id,
                    },
                  })
                "
              >
                {{ v.user_liked[0].emp_full_name.split(" ")[0] }}
              </CText>
              <CText d="inline-block" fontSize="12px" fontWeight="400">
                liked this post
              </CText>
            </CBox>
            <CBox v-if="v.user_liked && v.user_liked.length === 2">
              <CText
                d="inline-block"
                w="fit-content"
                fontSize="12px"
                fontWeight="600"
                :_hover="{ opacity: '0.8' }"
                cursor="pointer"
                mr="4px"
                @click.native="
                  $router.push({
                    name: 'Profile',
                    params: {
                      emp_id: v.user_liked[0].emp_id,
                    },
                  })
                "
              >
                {{ v.user_liked[0].emp_full_name.split(" ")[0] }}
              </CText>
              <CText fontWeight="400" fontSize="12px" d="inline-block" mr="4px">
                and
              </CText>
              <CText
                d="inline-block"
                w="fit-content"
                fontSize="12px"
                fontWeight="600"
                :_hover="{ opacity: '0.8' }"
                cursor="pointer"
                mr="4px"
                @click.native="
                  $router.push({
                    name: 'Profile',
                    params: {
                      emp_id: v.user_liked[1].emp_id,
                    },
                  })
                "
              >
                {{ v.user_liked[1].emp_full_name.split(" ")[0] }}
              </CText>
              <CText d="inline-block" fontSize="12px" fontWeight="400">
                liked this post</CText
              >
            </CBox>
            <CBox v-if="v.user_liked && v.user_liked.length === 3">
              <CText
                d="inline-block"
                w="fit-content"
                fontSize="12px"
                fontWeight="600"
                :_hover="{ opacity: '0.8' }"
                cursor="pointer"
                @click.native="
                  $router.push({
                    name: 'Profile',
                    params: {
                      emp_id: v.user_liked[0].emp_id,
                    },
                  })
                "
              >
                {{ v.user_liked[0].emp_full_name.split(" ")[0] }}
              </CText>
              <CText fontWeight="400" fontSize="12px" d="inline-block" mr="4px">
                ,
              </CText>
              <CText
                d="inline-block"
                w="fit-content"
                fontSize="12px"
                fontWeight="600"
                :_hover="{ opacity: '0.8' }"
                cursor="pointer"
                @click.native="
                  $router.push({
                    name: 'Profile',
                    params: {
                      emp_id: v.user_liked[1].emp_id,
                    },
                  })
                "
              >
                {{ v.user_liked[1].emp_full_name.split(" ")[0] }}
              </CText>
              <CText d="inline-block" fontSize="12px" fontWeight="400" mr="4px">
                , and</CText
              >
              <CText
                d="inline-block"
                w="fit-content"
                fontSize="12px"
                fontWeight="600"
                :_hover="{ opacity: '0.8' }"
                cursor="pointer"
                mr="4px"
                @click.native="
                  $router.push({
                    name: 'Profile',
                    params: {
                      emp_id: v.user_liked[2].emp_id,
                    },
                  })
                "
              >
                {{ v.user_liked[2].emp_full_name.split(" ")[0] }}
              </CText>
              <CText d="inline-block" fontSize="12px" fontWeight="400">
                liked this post</CText
              >
            </CBox>
            <CBox v-if="v.user_liked && v.user_liked.length > 3">
              <CText
                d="inline-block"
                w="fit-content"
                fontSize="12px"
                fontWeight="600"
                :_hover="{ opacity: '0.8' }"
                cursor="pointer"
                @click.native="
                  $router.push({
                    name: 'Profile',
                    params: {
                      emp_id: 3434,
                    },
                  })
                "
              >
                {{ v.user_liked[0].emp_full_name.split(" ")[0] }}
              </CText>
              <CText fontWeight="400" fontSize="12px" d="inline-block" mr="4px">
                ,
              </CText>
              <CText
                d="inline-block"
                w="fit-content"
                fontSize="12px"
                fontWeight="600"
                :_hover="{ opacity: '0.8' }"
                cursor="pointer"
                @click.native="
                  $router.push({
                    name: 'Profile',
                    params: {
                      emp_id: 3434,
                    },
                  })
                "
              >
                {{ v.user_liked[1].emp_full_name.split(" ")[0] }}
              </CText>
              <CText d="inline-block" fontSize="12px" fontWeight="400" mr="4px">
                , and</CText
              >
              <CText d="inline-block" fontSize="12px" fontWeight="600" mr="4px">
                {{ v.total_like - 2 }} Others</CText
              >
              <CText d="inline-block" fontSize="12px" fontWeight="400">
                liked this post</CText
              >
            </CBox>
            <CBox
              ml="auto"
              d="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <CBox
                cursor="pointer"
                :_hover="{ opacity: '1' }"
                :key="v.id"
                @click.native="
                  postResultLike(v.id);
                  $event.stopPropagation();
                "
                d="flex"
                flexDirection="column"
                alignItems="center"
              >
                <CImage
                  :src="
                    v.is_liked === 1
                      ? require('@/assets/icon/icon-heart-solid.svg')
                      : require('@/assets/icon/icon-heart-regular.svg')
                  "
                  w="24px"
                  h="24px"
                  mr="16px"
                />
                <CText fontSize="10px" color="#ACACAC" mr="16px" mt="4px">
                  {{ v.total_like }}
                </CText>
              </CBox>
              <CBox
                cursor="pointer"
                :_hover="{ opacity: '1' }"
                @click.native="
                  showCommentsModal(
                    v.id,
                    v.user.emp_full_name,
                    truncateText(v.title, 20)
                  );
                  $event.stopPropagation();
                "
                d="flex"
                flexDirection="column"
                alignItems="center"
              >
                <CImage
                  :src="require('@/assets/icon/icon-comment-regular.svg')"
                  w="24px"
                  h="24px"
                />
                <CText fontSize="10px" color="#ACACAC" mt="4px">
                  {{ v.total_comment }}
                </CText>
              </CBox>
            </CBox>
          </CBox>
          <CBox v-if="v.user_commented.length > 0">
            <CardCommentPrevList
              v-for="(comment, index) in v.user_commented"
              :key="index"
              :index="index"
              :id="comment.id"
              :result_id="comment.content_id"
              :sender_id="comment.emp_id.toString()"
              :sender="comment.user.emp_full_name"
              :body="comment.body"
              :isLikedByUser="comment.is_liked"
              :likes="comment.total_like"
              :current_user="currentUser"
              :created_at="comment.created_at"
              :updated_at="comment.updated_at"
              :type="'POST'"
              @updateData="updateData"
            />
          </CBox>
        </CBox>
      </CBox>
    </CBox>
    <CBox v-else>
      <CText>{{ isLoading ? "Loading..." : "There is no post yet." }}</CText>
    </CBox>
  </CBox>
</template>

<script>
import Loading from "vue-loading-overlay";
import { CBox, CText, CAvatar, CImage } from "@chakra-ui/vue";
import ModalComments from "@/components/modal/ModalComments.vue";
import { GET_CLUB_POSTS, DELETE_CLUB_POST } from "@/store/club.module";
import { POST_LIKE, REMOVE_LIKE } from "@/store/like.module";
import CardCommentPrevList from "@/components/card/CardCommentPrevList.vue";
import {
  SET_TOGGLE_COMMENTS_MODAL,
  GET_COMMENTS,
  GET_USER_SUGGESTIONS,
} from "@/store/comment.module";
import config from "@/ConfigProvider";
import moment from "moment-timezone";
import ButtonIcon from "@/components/button/ButtonIcon.vue";
import Swal from "sweetalert2";

export default {
  name: "DetailClub",
  components: {
    CBox,
    CText,
    CAvatar,
    CImage,
    ModalComments,
    Loading,
    ButtonIcon,
    CardCommentPrevList,
  },
  props: {
    current_user: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      isLoading: true,
      urlGethired: config.value("GETHIRED_WEB_URL"),
      isLoadingComment: false,
      currentPage: 1,
      result_id: "",
      titleComment: "",
      comments: "",
      mergedParticipants: {},
      defaultTimezone: "Asia/Jakarta",
      activeImages: {},
      dropdownStatus: [],
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser || [];
    },

    currentUrl() {
      return this.$route.fullPath;
    },
    currentSlug() {
      return this.$route.params.slug;
    },
    currentClub() {
      return this.$store.getters.currentClub || [];
    },
    clubPosts() {
      return this.$store.getters.clubPosts || [];
    },
    currentComments() {
      return this.$store.getters.currentComments;
    },
    currentUserSuggestions() {
      return this.$store.getters.currentUserSuggestions;
    },
    currentHashtagSuggestions() {
      return this.$store.getters.currentHashtagSuggestions;
    },
    currentCommentModalStatus() {
      return this.$store.getters.currentCommentModalStatus;
    },
    totalPages() {
      return Math.ceil(
        this.currentClubActivities.meta.total /
          this.currentClubActivities.meta.limit
      );
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    handleLogin() {
      window.location.href = this.urlGethired + "/signin";
    },
    async getData() {
      try {
        const params = {
          id: this.$route.params.slug,
          data: {
            page: this.currentPage,
            limit: 1000,
            sortBy: "update_at",
          },
        };
        await this.$store.dispatch(GET_CLUB_POSTS, params);
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },
    deleteData(id, title) {
      if (this.current_user) {
        Swal.fire({
          title: "Are you sure?",
          text: `You won't to delete ${title}!`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            const params = { id: id };
            try {
              Swal.fire({
                title: "Deleted!",
                text: "Your post has been deleted.",
                icon: "success",
              }).then(async () => {
                await this.getData();
              });
              await this.$store.dispatch(DELETE_CLUB_POST, params);
            } catch (err) {
              console.log(err);
            }
          }
        });
      }
    },
    formatDate(date) {
      return date
        ? moment(date)
            .tz(this.defaultTimezone)
            .format("DD MMMM YYYY [at] h:mm A")
        : "";
    },
    async postResultLike(id) {
      if (this.current_user) {
        try {
          await this.$store.dispatch(POST_LIKE, {
            type: "post",
            id: id,
          });
        } catch (err) {
          if (err.response.data.message === "Already like this content") {
            await this.$store.dispatch(REMOVE_LIKE, {
              type: "post",
              id: id,
            });
          }
        } finally {
          this.getData();
        }
      } else {
        this.handleLogin();
      }
    },
    async showCommentsModal(id, name, title) {
      try {
        this.isLoadingComment = true;
        this.result_id = id;
        this.titleComment = name + " - " + title;
        await this.$store.dispatch(GET_COMMENTS, { id: id });
        await this.$store.dispatch(GET_USER_SUGGESTIONS, {
          id: id,
        });
      } finally {
        this.isLoadingComment = false;
        this.$store.commit(SET_TOGGLE_COMMENTS_MODAL, true);
      }
    },
    directLinkChallenge(slug) {
      this.$router.push("/challenge/" + slug).then(() => {
        setTimeout(() => {
          window.location.reload(true);
        }, 0);
      });
    },
    truncateText(text, maxLength) {
      if (text && text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
      }
      return text;
    },
    parseUpdatedAt(updatedAt) {
      return new Date(updatedAt.replace(/ /g, "T"));
    },
    formatTimeAgo(updatedAt) {
      const now = new Date();
      const updatedAtDate = this.parseUpdatedAt(updatedAt);
      const timeDifference = now - updatedAtDate;

      if (timeDifference < 1000) {
        return "Just now";
      } else if (timeDifference < 60000) {
        const seconds = Math.floor(timeDifference / 1000);
        return `${seconds} seconds ago`;
      } else if (timeDifference < 3600000) {
        const minutes = Math.floor(timeDifference / 60000);
        return `${minutes} minutes ago`;
      } else if (timeDifference < 86400000) {
        const hours = Math.floor(timeDifference / 3600000);
        return `${hours} hours ago`;
      } else if (timeDifference < 2592000000) {
        const days = Math.floor(timeDifference / 86400000);
        return `${days} days ago`;
      } else if (timeDifference < 31536000000) {
        const months = Math.floor(timeDifference / 2592000000);
        return `${months} mounth ago`;
      } else {
        const years = Math.floor(timeDifference / 31536000000);
        return `${years} years ago`;
      }
    },
    formatDuration(duration) {
      const date = new Date(duration);
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();

      return `${minutes}.${seconds}`;
    },
    setCurrentPage(page) {
      this.currentPage = page;
      this.getData();
    },
    nextPhoto(postId, total) {
      // Pastikan ada entri untuk postId dalam activeImages
      if (!this.activeImages[postId]) {
        this.$set(this.activeImages, postId, 0);
      }
      // Tambahkan kondisi untuk memastikan bahwa indeks gambar aktif tidak melebihi panjang array v.photos
      if (this.activeImages[postId] < total - 1) {
        this.activeImages[postId]++;
      } else {
        // Jika sudah mencapai gambar terakhir, atur kembali ke gambar pertama
        this.activeImages[postId] = 0;
      }
    },
    prevPhoto(postId, total) {
      // Pastikan ada entri untuk postId dalam activeImages
      if (!this.activeImages[postId]) {
        this.$set(this.activeImages, postId, 0);
      }
      // Tambahkan kondisi untuk memastikan bahwa indeks gambar aktif tidak kurang dari 0
      if (this.activeImages[postId] > 0) {
        this.activeImages[postId]--;
      } else {
        // Jika sudah mencapai gambar pertama, atur kembali ke gambar terakhir
        this.activeImages[postId] = total - 1;
      }
    },
    redirectToDetailPost(postId) {
      this.$router
        .push({
          name: "DetailPostClub",
          params: {
            slug: this.currentSlug,
            id: postId,
          },
        })
        .then(() => {
          setTimeout(() => {
            window.location.reload(true);
          }, 0);
        });
    },
    setActiveImage(postId, index) {
      this.$set(this.activeImages, postId, index);
    },
    updateData() {
      this.getData();
    },
    toggleDropdown(index) {
      this.$set(this.dropdownStatus, index, !this.dropdownStatus[index]);
    },
    closeDropdown() {
      this.dropdownStatus = [];
    },
    formattedCaption(value) {
      const regex = /((?:https?|ftp):\/\/\S+)/g;
      const captionWithLinks = value.replace(
        regex,
        '<a href="$1" target="_blank" style="color:blue;">$1</a>'
      );
      return captionWithLinks;
    },
  },
};
</script>
