var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CBox',{attrs:{"w":"100%","px":"10px","py":"12px","mb":"8px","borderBottom":"1px solid var(--Neutral-Light-Gray, #EFF1F5)"}},[_c('CBox',{attrs:{"mb":"1","d":"flex","justifyContent":"space-between","alignItems":"center"}},[_c('CBox',{attrs:{"d":"flex","alignItems":"center"}},[_c('CAvatar',{attrs:{"w":"35px","h":"35px","mr":"12px","name":_vm.sender.split(' ')[0],"_hover":{ opacity: '0.8' },"cursor":"pointer"},nativeOn:{"click":function($event){return _vm.$router.push({
            name: 'Profile',
            params: {
              emp_id: _vm.sender_id,
            },
          })}}}),_c('CBox',[_c('CBox',{attrs:{"d":['block', 'flex', 'flex', 'flex'],"alignItems":"center"}},[_c('CText',{attrs:{"fontFamily":"Montserrat","fontStyle":"normal","fontSize":"14px","fontWeight":"600","color":"fontMain","mr":"8px","_hover":{ opacity: '0.8' },"cursor":"pointer"},nativeOn:{"click":function($event){return _vm.$router.push({
                name: 'Profile',
                params: {
                  emp_id: _vm.sender_id,
                },
              })}}},[_vm._v(_vm._s(_vm.current_user.emp_id == _vm.sender_id ? "You" : _vm.sender))]),_c('CText',{attrs:{"fontFamily":"Montserrat","fontStyle":"normal","fontSize":"12px","fontWeight":"500","color":"breadcrumbActive"}},[_vm._v(_vm._s(_vm.formatCommentDate(_vm.created_at)))])],1),_c('CBox',[_c('CText',{attrs:{"id":_vm.bodyElementId,"fontFamily":"Montserrat","fontStyle":"normal","fontSize":"14px","fontWeight":"400","color":"fontMain","py":"16px","p":"0"}})],1)],1)],1),_c('CBox',{attrs:{"w":"fit-content","d":['block', 'flex', 'flex', 'flex'],"cursor":"pointer","alignItems":"center"},on:{"click":_vm.postCommentLike}},[_c('ButtonIcon',{attrs:{"icon":_vm.isLikedByUser ? 'icon-heart-solid.svg' : 'icon-heart-regular.svg',"btnWidth":"14px","btnHeight":"14px"}}),_c('CText',{attrs:{"d":['none', 'block', 'block', 'block'],"fontFamily":"Montserrat","fontStyle":"normal","fontSize":"10px","fontWeight":"600","color":"#B5B5B5","ml":"1"}},[_vm._v(_vm._s(`${_vm.likes} ${_vm.likes > 1 ? "likes" : "like"}`))]),_c('CText',{attrs:{"d":['block', 'none', 'none', 'none'],"fontFamily":"Montserrat","fontStyle":"normal","fontSize":"10px","fontWeight":"600","color":"#B5B5B5","ml":"1"}},[_vm._v(_vm._s(_vm.likes))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }