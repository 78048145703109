<template>
  <CBox
    w="100%"
    px="10px"
    py="12px"
    mb="8px"
    borderBottom="1px solid var(--Neutral-Light-Gray, #EFF1F5)"
  >
    <CBox mb="1" d="flex" justifyContent="space-between" alignItems="center">
      <CBox d="flex" alignItems="center">
        <CAvatar
          w="35px"
          h="35px"
          mr="12px"
          :name="sender.split(' ')[0]"
          :_hover="{ opacity: '0.8' }"
          cursor="pointer"
          @click.native="
            $router.push({
              name: 'Profile',
              params: {
                emp_id: sender_id,
              },
            })
          "
        ></CAvatar>
        <CBox>
          <CBox :d="['block', 'flex', 'flex', 'flex']" alignItems="center">
            <CText
              fontFamily="Montserrat"
              fontStyle="normal"
              fontSize="14px"
              fontWeight="600"
              color="fontMain"
              mr="8px"
              :_hover="{ opacity: '0.8' }"
              cursor="pointer"
              @click.native="
                $router.push({
                  name: 'Profile',
                  params: {
                    emp_id: sender_id,
                  },
                })
              "
              >{{ current_user.emp_id == sender_id ? "You" : sender }}</CText
            >
            <CText
              fontFamily="Montserrat"
              fontStyle="normal"
              fontSize="12px"
              fontWeight="500"
              color="breadcrumbActive"
              >{{ formatCommentDate(created_at) }}</CText
            >
          </CBox>
          <CBox>
            <CText
              :id="bodyElementId"
              fontFamily="Montserrat"
              fontStyle="normal"
              fontSize="14px"
              fontWeight="400"
              color="fontMain"
              py="16px"
              p="0"
            ></CText>
          </CBox>
        </CBox>
      </CBox>
      <CBox
        w="fit-content"
        :d="['block', 'flex', 'flex', 'flex']"
        cursor="pointer"
        alignItems="center"
        @click="postCommentLike"
      >
        <ButtonIcon
          :icon="
            isLikedByUser ? 'icon-heart-solid.svg' : 'icon-heart-regular.svg'
          "
          btnWidth="14px"
          btnHeight="14px"
        />
        <CText
          :d="['none', 'block', 'block', 'block']"
          fontFamily="Montserrat"
          fontStyle="normal"
          fontSize="10px"
          fontWeight="600"
          color="#B5B5B5"
          ml="1"
          >{{ `${likes} ${likes > 1 ? "likes" : "like"}` }}</CText
        >
        <CText
          :d="['block', 'none', 'none', 'none']"
          fontFamily="Montserrat"
          fontStyle="normal"
          fontSize="10px"
          fontWeight="600"
          color="#B5B5B5"
          ml="1"
          >{{ likes }}</CText
        >
      </CBox>
    </CBox>
  </CBox>
</template>

<script>
import moment from "moment-timezone";

import config from "@/ConfigProvider";
import { POST_LIKE, REMOVE_LIKE } from "@/store/like.module";
import {
  GET_COMMENTS,
  REMOVE_COMMENT,
  SET_SELECTED_COMMENT,
} from "@/store/comment.module";
import ButtonIcon from "@/components/button/ButtonIcon.vue";

export default {
  components: {
    ButtonIcon,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    id: {
      type: Number,
      default: 0,
    },
    result_id: {
      type: String,
      default: "",
    },
    challenge_slug: {
      type: String,
      default: "",
    },
    sender_id: {
      type: String,
      default: "",
    },
    sender: {
      type: String,
      default: "",
    },
    body: {
      type: String,
      default: "",
    },
    likes: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isLikedByUser: {
      type: Boolean,
      default: false,
    },
    current_user: {
      type: Object,
      default: () => {
        return null;
      },
    },
    base_url: {
      type: String,
      default: "",
    },
    created_at: {
      type: String,
      default: "",
    },
    updated_at: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      bodyElementId: "",
      linkRegex: /(https?:\/\/[^\s]+)/g,
      hashtagRegex: /#\S+/g,
      defaultTimezone: config.value("DEFAULT_TIMEZONE"),
    };
  },
  watch: {
    body(val) {
      this.parseContentOnBody(val);
    },
  },
  created() {
    this.bodyElementId = `bodyParagraph${this.id}`;
  },
  mounted() {
    this.parseContentOnBody(this.body);
  },
  methods: {
    parseLinkOnBody(sentence) {
      return sentence.replace(this.linkRegex, (link) => {
        return `<a class="content__link" href="${link}" target="_blank">${link}</a>`;
      });
    },
    parseHashtagOnBody(sentence) {
      return sentence.replace(this.hashtagRegex, (hashtag) => {
        return `<a class="content__link" href="${
          window.location.origin
        }/leaderboard/${this.challenge_slug}?hashtag=${hashtag.replace(
          "#",
          ""
        )}"target="_blank">${hashtag}</a>`;
      });
    },
    parseContentOnBody(sentence) {
      const parsedSentence = this.parseHashtagOnBody(
        this.parseLinkOnBody(sentence)
      );

      document.getElementById(this.bodyElementId).innerHTML = parsedSentence;
    },
    redirectToSignIn() {
      window.location.href = this.base_url + "/signin";
    },
    isUserComment() {
      return (
        this.current_user &&
        this.current_user.emp_id === parseInt(this.sender_id)
      );
    },
    formatCommentDate(date) {
      return moment(date).tz(this.defaultTimezone).fromNow();
    },
    async getAllComments() {
      await this.$store.dispatch(GET_COMMENTS, { id: this.result_id });
    },
    getSelectedComment() {
      this.$store.commit(SET_SELECTED_COMMENT, {
        id: this.id,
        body: this.body,
      });
    },
    async removeComment() {
      await this.$store.dispatch(REMOVE_COMMENT, { id: this.id });
      this.getAllComments();
      this.$emit("updateData");
    },
    async postCommentLike() {
      if (this.current_user) {
        try {
          await this.$store.dispatch(POST_LIKE, {
            type: "comment",
            id: this.id,
          });
        } catch (err) {
          if (err.response.data.message === "Already like this content") {
            await this.$store.dispatch(REMOVE_LIKE, {
              type: "comment",
              id: this.id,
            });
          }
        } finally {
          this.getAllComments();
          this.$emit("updateData");
        }
      } else {
        this.redirectToSignIn();
      }
    },
  },
};
</script>

<style>
.content__link {
  color: #29aafe;
}
</style>
